import React from "react";
import PropTypes from "prop-types";
import Icon from "../../components/Icon";
import Modal from "../../components/Modal";
import JoinCompetitionForm from "./JoinCompetitionForm";
import LoadingIndicator from "../../components/LoadingIndicator";
import { translate } from "react-translate";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actions as digiThemeActions } from "../../utils/digiTheme";
import scopedBindActionCreators from "../../utils/scopedReducer";
import { fixDate } from "../../utils/date";
import { joinCompetition } from "../../redux/competition";
import { getUserDetails } from "../../redux/user";
import { Link, withRouter } from "react-router-dom";
import config from "../../config/TellTur";

import "./CompetitionsList.scss";

const mapStateToProps = state => ({
  user: state.user.user,
  turmalRecords: state.konkurannseTurmal.records,
  turmalLoading: state.konkurannseTurmal.loading
});

const mapDispatchToProps = dispatch => {
  return {
    joinCompetition: bindActionCreators(joinCompetition, dispatch),
    getUserDetails: bindActionCreators(getUserDetails, dispatch),
    readKonkurannseTurmal: scopedBindActionCreators(
      digiThemeActions.readFiltered,
      dispatch,
      "konkurannseTurmal"
    ),
    resetDigiTheme: scopedBindActionCreators(
      digiThemeActions.resetDigiTheme,
      dispatch,
      "konkurannseTurmal"
    )
  };
};

class CompetitonsList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      index: null,
      showModal: false,
      competitionId: undefined
    };
    this.toggleModal = this.toggleModal.bind(this);
    this.readKonkurannser = this.readKonkurannser.bind(this);
  }

  componentDidMount() {
    !this.props.user && this.props.getUserDetails();
  }

  readKonkurannser(id) {
    this.props.readKonkurannseTurmal(
      [
        "id",
        "points",
        "fk_telltur_konkurranse",
        "turmal_id",
        "turmal_type",
        "turmal_navn",
        "turmal_uri",
        "telltur_turmaal_id"
      ],
      {
        filterColumns: [
          {
            name: "fk_telltur_konkurranse",
            netType: "id[]",
            value: id,
            comparisonOperator: "@="
          }
        ],
        sortColums: [
          {
            name: "points"
          }
        ]
      },
      config.mapProjCode
    );
  }

  toggleModal() {
    if (!this.props.user) {
      this.props.history.push("/logginn");
    }
    this.setState(prevState => ({ showModal: !prevState.showModal }));
  }

  render() {
    const { index, competitionId } = this.state;
    const { t, competitions, user, turmalRecords, turmalLoading, resetDigiTheme, loading } =
      this.props;

    return competitions.map((competition, idx) => {
      const endDate = fixDate(competition.end_date);
      var tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);

      const hasExpired = endDate < tomorrow;

      return (
        <div
          key={competition.title + idx}
          className={["competiton__container", index === idx ? "active" : ""].join(" ")}
        >
          <button
            className="container__title--button"
            onClick={() => {
              if (idx === index) {
                this.setState({ index: undefined, competitionId: undefined });
                resetDigiTheme();
              } else {
                this.setState({ index: idx, competitionId: competition.id });
                this.readKonkurannser(competition.id);
              }
            }}
          >
            <span className="content__label">{competition.title}</span>
            <Icon name={index === idx ? "chevronUp" : "chevronDown"} />
          </button>
          <div className="container__content">
            {index === idx && !loading && !turmalLoading ? (
              <React.Fragment>
                <div className="container__content--description">{competition.description}</div>
                {competition.image && (
                  <div className="container__content--media">
                    <img
                      alt={competition.title}
                      src={
                        config.adaptiveUrl +
                        "WebServices/generic/Media.asmx/Download?thumbnail_size=medium&uuid=" +
                        competition.image
                      }
                    />
                  </div>
                )}
                {!hasExpired && (
                  <button onClick={this.toggleModal} className="btn btn-primary">
                    {t("Participate")}
                  </button>
                )}

                <div className="competitions-list--terms">
                  {(competition.municipality ||
                    competition.year_of_birth_from ||
                    competition.year_of_birth_to ||
                    competition.start_date ||
                    competition.end_date) && (
                    <span className="competitions-list--bold-text">{t("terms")}</span>
                  )}
                  {competition.municipality && (
                    <div className="container__content--municipality">
                      <span className="content__label">{t("Municipality")}</span>
                      {competition.municipality}
                    </div>
                  )}
                  {(competition.year_of_birth_from || competition.year_of_birth_to) && (
                    <React.Fragment>
                      <div className="container__content--birth">
                        <span className="content__label">{t("YearOfBirthFrom")}</span>
                        {competition.year_of_birth_from}
                      </div>
                      <div className="container__content--birth">
                        <span className="content__label">{t("YearOfBirthTo")}</span>
                        {competition.year_of_birth_to}
                      </div>
                    </React.Fragment>
                  )}
                  {(competition.start_date || competition.end_date) && (
                    <React.Fragment>
                      <div className="container__content--date">
                        <span className="content__label">{t("StartDate")}</span>
                        {competition.start_date
                          ? `${fixDate(competition.start_date).toLocaleDateString(t("locale"))}`
                          : null}
                      </div>
                      <div className="container__content--date">
                        <span className="content__label">{t("EndDate")}</span>
                        {competition.end_date
                          ? `${fixDate(competition.end_date).toLocaleDateString(t("locale"))}`
                          : null}
                      </div>
                    </React.Fragment>
                  )}
                </div>

                {turmalRecords && turmalRecords.length > 0 && (
                  <div className="competitions-list--destinations">
                    <span className="competitions-list--bold-text">{t("destinations")}</span>
                    {turmalRecords.map((turmal, i) => {
                      if (!turmal.telltur_turmaal_id || !turmal.turmal_navn) {
                        return (
                          <div key={"slettet_turmal" + i} className="turmal">
                            Turmål slettet
                          </div>
                        );
                      }
                      return (
                        <div key={turmal.turmal_navn + i} className="turmal">
                          <Link
                            target="_blank"
                            rel="noopener noreferrer"
                            to={`/turmaal/${turmal.telltur_turmaal_id.replace("_", "/")}`}
                          >{`${turmal.turmal_navn} (${turmal.points} ${t("points")})`}</Link>
                          <br />
                        </div>
                      );
                    })}
                  </div>
                )}
                {!hasExpired && (
                  <button onClick={this.toggleModal} className="btn btn-primary">
                    {t("Participate")}
                  </button>
                )}
              </React.Fragment>
            ) : (
              <div className="loader">
                <LoadingIndicator />
              </div>
            )}
          </div>
          {this.state.showModal && (
            <Modal>
              <JoinCompetitionForm
                competitionId={competitionId}
                users={user && user.familymembers}
                toggleModal={this.toggleModal}
              />
            </Modal>
          )}
        </div>
      );
    });
  }
}

CompetitonsList.propTypes = {
  competitions: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  getUserDetails: PropTypes.func.isRequired,
  readKonkurannseTurmal: PropTypes.func.isRequired,
  resetDigiTheme: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  user: PropTypes.object,
  turmalRecords: PropTypes.array,
  turmalLoading: PropTypes.bool
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(translate("CompetitionsList")(CompetitonsList))
);
