import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { addMember, readMembers, deleteMember } from "../redux/familymembers";
import Title from "./Title";
import AddMemberPage from "./AddMemberPage";

import ballek from "../static/ballek.png";
import "./AddMemberPage.scss";

const mapDispatchToProps = dispatch => {
  return {
    addMember: bindActionCreators(addMember, dispatch),
    readMembers: bindActionCreators(readMembers, dispatch),
    deleteMember: bindActionCreators(deleteMember, dispatch)
  };
};

const mapStateToProps = state => ({
  members: state.familyMembers.members,
  memberError: state.familyMembers.error,
  membersLoading: state.familyMembers.loading,
  userLoading: state.user.loading,
  user: state.user.user
});

class AddMemberContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      serverError: null
    };

    this.addNewMember = this.addNewMember.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.user &&
      nextProps.members === null &&
      !nextProps.membersLoading
    ) {
      this.props.readMembers(nextProps.user.uuid);
    }
    if (nextProps.memberError) {
      this.setState({ serverError: nextProps.memberError });
    }
  }

  addNewMember(user, callback) {
    this.setState({ serverError: null });
    var member = {
      nick_name: user.newUserNickname,
      yearofbirth: user.newUserYearOfBirth,
      gender: user.gender,
      parent_user_uuid: this.props.user.uuid,
      selected_friluftsraad_uuid: user.selected_friluftsraad_uuid
    };
    this.props.addMember(member, callback);
  }

  render() {
    const { page, membersLoading, userLoading, user, members } = this.props;
    const { serverError } = this.state;

    return (
      <div className="tell-tur-page addMember">
        <Title title={page.title} />
        <AddMemberPage
          page={page}
          membersLoading={membersLoading}
          userLoading={userLoading}
          user={user}
          members={members}
          serverError={serverError}
          addNewMember={this.addNewMember}
          readMembers={this.props.readMembers}
          deleteMember={this.props.deleteMember}
        />
        <img src={ballek} alt="" />
      </div>
    );
  }
}

AddMemberContainer.propTypes = {
  page: PropTypes.object,
  members: PropTypes.array,
  memberError: PropTypes.object,
  membersLoading: PropTypes.bool,
  userLoading: PropTypes.bool,
  user: PropTypes.object,
  addMember: PropTypes.func,
  deleteMember: PropTypes.func,
  readMembers: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(AddMemberContainer);
