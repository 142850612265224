import React from "react";
import PropTypes from "prop-types";
import MemberSelect from "../../components/MyTrips/MemberSelect";
import LoadingIndicator from "../../components/LoadingIndicator";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { translate } from "react-translate";
import { joinCompetition, resetState } from "../../redux/competition";

import "./JoinCompetitionForm.scss";

const mapDispatchToProps = (dispatch) => {
  return {
    joinCompetition: bindActionCreators(joinCompetition, dispatch),
    resetCompetitionState: bindActionCreators(resetState, dispatch),
  };
};

const mapStateToProps = (state) => ({
  joinCompetitionLoading: state.joinCompetitionReducer.loading,
  joinCompetitionError: state.joinCompetitionReducer.error,
  joinCompetitionSuccess: state.joinCompetitionReducer.success,
  joinedCompetitions: state.joinCompetitionReducer.competitions,
  user: state.user.user,
});

class JoinCompetitionForm extends React.Component {
  constructor() {
    super();

    this.state = {
      selectedMemberId: -1,
      error: null,
    };
    this.changeCurrentMember = this.changeCurrentMember.bind(this);
    this.handleCompetitionJoin = this.handleCompetitionJoin.bind(this);
  }

  componentDidMount() {
    if (this.state.selectedMemberId === -1) {
      const mainMember = this.props.users.find((m) => m.is_main_familymember);
      if (mainMember) {
        this.setState({
          selectedMemberId: mainMember.id,
        });
      }
    }
    this.props.resetCompetitionState();
  }

  componentWillUnmount() {
    this.props.resetCompetitionState();
  }

  changeCurrentMember(id) {
    if (!Number.isNaN(id)) {
      this.setState({
        selectedMemberId: id,
      });
    }
    this.props.resetCompetitionState();
  }

  handleCompetitionJoin(...props) {
    this.props.joinCompetition(...props);
  }

  render() {
    const {
      competitionId,
      joinCompetitionLoading,
      joinCompetitionError,
      joinCompetitionSuccess,
      t,
    } = this.props;
    const { selectedMemberId, error } = this.state;
    return (
      <div>
        <h2>{t("ConfirmJoin")}</h2>

        <MemberSelect
          infoText={t("ChooseMember")}
          members={this.props.users}
          memberError={error}
          changeCurrentMember={this.changeCurrentMember}
          selectedMemberId={selectedMemberId}
          hideTripCount
        />

        {joinCompetitionError && (
          <p className="text-danger">{t(`${joinCompetitionError.msg}`)}</p>
        )}
        {joinCompetitionSuccess && (
          <p className="text-success">{t("Success")}</p>
        )}

        <div className="btn-flex-container">
          {!joinCompetitionSuccess && <button
            onClick={this.props.toggleModal}
            className="btn btn-secondary"
          >
            {t("ButtonCancel")}
          </button>}

          {!joinCompetitionSuccess && <button
            className="btn btn-primary"
            onClick={() => {
              this.handleCompetitionJoin(competitionId, selectedMemberId);
            }}
          >
            {t("ButtonConfirm")}
          </button>}

          {joinCompetitionSuccess && <button
            onClick={this.props.toggleModal}
            className="btn btn-primary"
          >
            {t("ButtonClose")}
          </button>}
        </div>
        {joinCompetitionLoading && (
          <div className="loader">
            <LoadingIndicator />
          </div>
        )}
      </div>
    );
  }
}

JoinCompetitionForm.propTypes = {
  users: PropTypes.array.isRequired,
  competitionId: PropTypes.number.isRequired,
  toggleModal: PropTypes.func.isRequired,
  joinCompetition: PropTypes.func.isRequired,
  resetCompetitionState: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  joinCompetitionLoading: PropTypes.bool,
  joinCompetitionError: PropTypes.object,
  joinCompetitionSuccess: PropTypes.bool,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(translate("JoinCompetitionForm")(JoinCompetitionForm));
