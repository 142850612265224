import React, { useEffect } from "react";
import { useLocation } from "react-router";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import CoreLayout from "../layouts/CoreLayout";
import MapLayout from "../layouts/MapLayout";
import IndexLayout from "../layouts/IndexLayout";
import { connect } from "react-redux";
import { getUserDetails } from "../redux/user";
import PageView from "../views/PageView";
import HybridPageView from "../views/HybridPageView";
import LogoutView from "../views/LogoutView";
import ArticleView from "../views/ArticleView";
import ArticleDetailView from "../views/ArticleDetailView";
import RegisterTripView from "../views/RegisterTripView";
import PropTypes from "prop-types";
import PrivateRoute from "./PrivateRoute";
import scopedBindActionCreators from "../utils/scopedReducer";
import { actions as digiThemeActions } from "../utils/digiTheme";
import FriluftsradListPage from "../components/FriluftsradListPage";

import config from "../config/TellTur";

function ScrollToTop() {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return <></>;
}

const mapDispatchToProps = dispatch => {
  return {
    getUserDetails: () => {
      dispatch(getUserDetails());
    },
    readMunicipalities: scopedBindActionCreators(
      digiThemeActions.readFiltered,
      dispatch,
      "municipalities"
    ),
    readFriluftsraad: scopedBindActionCreators(
      digiThemeActions.readFiltered,
      dispatch,
      "readFriluftsraad"
    )
  };
};

class AppRouter extends React.Component {
  componentDidMount() {
    const splashScreen = document.getElementById("splash-screen");

    this.props.getUserDetails();
    this.props.readMunicipalities(
      config.dtMunicipality.columns,
      null,
      config.mapProjCode,
      0,
      1000,
      null
    );
    this.props.readFriluftsraad(
      config.friluftsraadDt.columns,
      null,
      config.mapProjCode,
      0,
      1000,
      null
    );

    if (splashScreen) {
      splashScreen.remove();
    }
  }

  render() {
    return (
      <Router basename={process.env.PUBLIC_URL}>
        <ScrollToTop />
        <Switch>
          <Route exact path="/">
            <IndexLayout>
              <Switch>
                <Route exact path="/" component={PageView} />
              </Switch>
            </IndexLayout>
          </Route>
          <Route path="/alleturmaal">
            <MapLayout>
              <Switch>
                <Route path="/alleturmaal/:activeSources*" component={ArticleView} />
              </Switch>
            </MapLayout>
          </Route>
          <Route path="/turmaal">
            <MapLayout>
              <Switch>
                <Route path="/turmaal/:sourceId/:articleId" component={ArticleDetailView} />
              </Switch>
            </MapLayout>
          </Route>
          <CoreLayout>
            <Switch>
              <PrivateRoute path="/loggut" component={LogoutView} loginPath="/logginn" />
              <Route strict exact path="/logginn" component={HybridPageView} />
              <PrivateRoute
                strict
                exact
                path="/minprofil"
                component={HybridPageView}
                loginPath="/logginn"
              />
              <Route strict exact path="/brukerregistrering" component={HybridPageView} />
              <Route strict exact path="/glemtpassord" component={HybridPageView} />
              <PrivateRoute
                strict
                exact
                path="/endrepassord"
                component={HybridPageView}
                loginPath="/logginn"
              />
              <PrivateRoute
                strict
                exact
                path="/nyttmedlem"
                component={HybridPageView}
                loginPath="/logginn"
              />
              <PrivateRoute
                strict
                exact
                path="/mineturer"
                component={HybridPageView}
                loginPath="/logginn"
              />
              <PrivateRoute
                strict
                exact
                path="/registrertur"
                component={RegisterTripView}
                loginPath="/logginn"
              />
              <Route path="/resultater" component={HybridPageView} />
              <Route exact path="/friluftsrad" component={FriluftsradListPage} />
              <Route path="/friluftsrad/:rad" component={HybridPageView} />
              <Route path="/*" component={PageView} />
            </Switch>
          </CoreLayout>
        </Switch>
      </Router>
    );
  }
}

AppRouter.propTypes = {
  getUserDetails: PropTypes.func,
  readMunicipalities: PropTypes.func.isRequired,
  readFriluftsraad: PropTypes.func.isRequired
};

export default connect(null, mapDispatchToProps)(AppRouter);
