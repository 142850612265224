export default {
  locale: "en",

  LoginPage: {
    LogIn: "Log in",
    Username: "Email",
    Password: "Password",
    ForgotPassword: "Forgot your password?",
    NewUser: "New TellTur-user?",
    RegisterHere: "Register here",
    Error: "Login failed. Please ensure your email and password are correct",
    sessionExpired: "Your session has expired. Please sign in again, to get a new session."
  },

  UserRegistrationPage: {
    UsernameTooShort: "Username is too short",
    PasswordsDoNotMatch: "Passwords do not match",
    PasswordIsTooShort: "Password is too short",
    FirstnameNotValid: "First name not valid",
    SurnameNotValid: "Surname not valid",
    MobileNotValid: "Mobile number not valid",
    EmailAddressNotValid: "Email address not valid",
    MunicipalityNotValid: "Municipality not valid",
    YearOfBirthNotValid: "Year of birth is not valid",
    ToLogin: "Go to login",
    SelectedFriluftsraadIdIsMissing: "Please choose a friluftsråd",
    SelectedCompanyIdIsInvalid: "Selected company is invalid"
  },

  UserRegistrationForm: {
    Register: "Register",
    Username: "* Username/nickname",
    Password: "* Password",
    PasswordRepeat: "* Repeat the password",
    Firstname: "* First name (only visible to the staff of friluftsrådene)",
    Surname: "* Surname (only visible to the staff of friluftsrådene)",
    Mobile: "* Mobile phone number",
    Email: "* Email address",
    Municipality: "* Municipality",
    YearOfBirth: "* Year of birth",
    AddCompany: "Choose a company (optional)",
    ChooseFriluftsraad: "Choose friluftsråd",
    ChooseMunicipality: "Choose municipality",
    Agree: "Yes, I allow Friluftsrådene to send me emails regarding TellTur and trip competitions."
  },

  ResetPasswordPage: {
    Email: "Email",
    SendNewPassword: "Send new password",
    Cancel: "Cancel",
    CouldNotResetPassword:
      "Could not reset password. Please make sure the email-address is correct.",
    PasswordResetInfoText:
      "A new password has been sent to your email address. If you can't find it please check your spam-filter",
    GoBack: "Back to login"
  },

  ChangePasswordPage: {
    CurrentPassword: "Current password",
    NewPassword: "New password",
    RepeatNewPassword: "Repeat new password",
    Cancel: "Cancel",
    SaveNewPassword: "Save new password",
    PasswordChanged: "Your password was successfully changed",
    GoBack: "Back to your profile",
    CouldNotChangePassword: "Could not change your password"
  },

  MyProfilePage: {
    ChangePassword: "Change password",
    EditFamilyMembers: "Edit familymembers",
    DeleteAccount: "Delete account",
    ConfirmDelete: "I understand, delete my account",
    SelectedFriluftsraadIsInvalid: "Selected friluftsråd is invalid",
    SelectFriluftsraad: "Select friluftsraad",
    ConnectedToFriluftsraad: "Connected to friluftsråd",
    UsernameTooShort: "Username is too short",
    FirstnameNotValid: "First name not valid",
    SurnameNotValid: "Surname not valid",
    MobileNotValid: "Mobile number not valid",
    EmailAddressNotValid: "Email address not valid",
    MunicipalityNotValid: "Municipality not valid",
    YearOfBirthNotValid: "Year of birth is not valid",
    ConnectedToCompany: "Connect to company (optional)"
  },

  EditableDropdown: {
    Cancel: "Cancel",
    Save: "Save"
  },

  MyProfileForm: {
    Save: "Save",
    Nickname: "Username/nickname (will be visible in result tables)",
    Fullname: "Full name (only visible to you and the employees of friluftsrådene)",
    Mobile: "Mobile phone number",
    Email: "Email address",
    Municipality: "Municipality",
    YearOfBirth: "Year of birth",
    SelectedFriluftsraad: "Select friluftsråd"
  },

  AddMemberPage: {
    Nickname: "Nickname",
    Year: "Year",
    AddNewUser: "Add",
    Delete: "Delete",
    FriluftsraadNotValid: "Cannot find valid friluftsråd-id. Please update your profile"
  },

  RegisterTripForm: {
    RegisterTrip: "Register your trip",
    SeeRegisteredTripsOn: "You can see all your registered trips on ",
    ThisPage: "this page",
    TripCode: "* Trip code (the code you found at the trip destination)",
    TripDate: "* Trip date",
    Cancel: "Cancel",
    Save: "Lagre",
    RegisterTripAsMember: "* Register trip as member"
  },

  RegisterTripPage: {
    NotLoggedIn: "You need to be logged in to register trips",
    TripCodeNotValid: "Trip code is not valid",
    TripDateNotValid:
      "Trip date is not valid. Please enter a date in the following format: dd.mm.yyyy",
    SelectAMember: "Please select a family member",
    CantRegisterTripsFromLastYear: "Cannot register trips from last year",
    CantRegisterTripsInFuture: "Cannot register trips from the future",
    Title: "Register trip"
  },

  MemberList: {
    Trips: "Trips"
  },

  ArticleView: {
    showFriluftsrad: "Show friluftsråd",
    showTipBox: "Show tipbox",
    Title: "All destinations"
  },

  ArticleDetailView: {
    title: "TellTur: Destination"
  },

  NavBar: {
    MyProfile: "My profile",
    LogOut: "Log out"
  },

  TellturSearch: {
    tellturSearchPlaceholder: "Search for hiking destinations",
    tellturSearchPlaceholderSelected: "Search for {{sources}}"
  },

  DestinationStats: {
    locale: "en",
    lastVisitors: "Last five",
    topVisitors: "Top five",
    visitorStatistics: "Visitor statistics",
    noVisitors: "No visitors registered"
  },

  ResultsPage: {
    MostPoints: "Most points",
    Points: "Points",
    Nickname: "Username",
    LoadAll: "Load all",
    showTopFive: "Show top five",
    UniqueDestinations: "Unique destinations",
    Destination: "Destination",
    Friluftsraad: "Friluftsråd",
    Company: "Company"
  },

  TranslatedServerError: {
    UserExists: 'A user with the username "{{username}}" already exists',
    InvalidUser: "Invalid user-data",
    InvalidTurmaal: "No destination with this code was found.",
    InvalidOwner: "You do not own the object you are changing. Contact your administrator.",
    EmailInUse: "This email adress is already in use",
    TurmaalNotActive: "The contest for this hiking destination is not currently active.",
    TURMAAL_ALREADY_REGISTERED_TODAY:
      "You have already registered a visit on this destinatnion on the chosen date.",
    DATO_ERROR: "Dateformat is invalid",
    INVALID_FAMILY_MEMBER: "Invaldig familymember chosen, please try again"
  },

  MyTripsContainer: {
    NoTrips: "No trips registered"
  },

  TripList: {
    locale: "en"
  },

  PageView: {
    Welcome: "Welcome",
    ReminderText1:
      "We noticed that this was your first login to the new telltur.no. Please ensure that your profile contains the correct informaion by reviewing your profile.",
    ReminderText2: "This reminder will only be shown once."
  },

  Competitions: {
    CompetitionsTheme: "Competitions",
    Description:
      "In this box you will find a list of the competitions in {0}. " +
      "Click the arrows to read more. The competitions you can enter has a green " +
      "button at the bottom.",
    LinkToResults: "Your results.",
    LinkToYourProfile: "Your registered trips.",
    NoCompetitions: "This Friluftsråd does not have any active competitions."
  },

  CompetitionsList: {
    locale: "en",
    Municipality: "Municipality: ",
    YearOfBirthFrom: "From year of birth: ",
    YearOfBirthTo: "To year of birth: ",
    StartDate: "Start date: ",
    EndDate: "End date: ",
    Participate: "Join"
  },

  CompetitionResults: {
    Title: "Resultater fra temakonkurranser",
    CompetitionDescription:
      "Nedenfor vises resultatlister fra de temakonkurranser du deltar i. Oversikt over disse med mulighet for påmelding finner du på ",
    LinkToFriluftsrad: "dit friluftsråds informasjonsside",
    NoUniqueJoinedCompetitions: "No joined competitions",
    Nickname: "Brukernavn",
    Points: "Poeng"
  },

  JoinCompetitionForm: {
    ConfirmJoin: "Bekreft påmelding",
    ConfirmDescription:
      "Betingelser for konkurranser. f.eks. at den kun er for deltagere mellom 8 og 11 år. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    ChooseMember: "Velg brukeren som skal meldes på temakonkurransen: ",
    Success: "You have successfully joined competition",
    ButtonCancel: "Cancel",
    ButtonClose: "Close",
    ButtonConfirm: "OK"
  },

  CompetitionPage: {
    title: "Dine temakonkurranser",
    Description:
      "Nedenfor vises de lokale temakonkurranser du og dine ekstra turgåere er påmeldt. Du kan " +
      "melde deg av ved å klikke på krysset til høyre for hver konkurranse. Les mer om hver " +
      "enkelt temakonkurranse ",
    LinkToFriluftsrad: "hos ditt friluftsråd",
    Note: "Merk at du fremdeles er med i TellTurkonkurransen selv om du melder deg av en temakonkurranse",
    NoMemberCompetitions: "No joined competitions",
    CancelationConfirm: "Bekreft avmelding",
    CancelationDescription:
      "Når du melder deg av en temakonkurranse blir alle poeng du har samlet i denne konkurransen slettet. Dersom du ønsker å gjenoppta deltagelsen vil du måtte starte poengsankingen på nytt.",
    CancelationDescriptionNote:
      "De ordinære TellTurpoengene dine (i den nasjonale konkurransen) blir ikke berørt av avmeldingen.",
    ButtonCancel: "Avbyrt",
    ButtonConfirm: "Meld av"
  }
};
