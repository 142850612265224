const getPath = location => {
  return location.pathname.length > 1 ? location.pathname.slice(1) : 'index';
};

const fixLinks = history => {
  let pageContent = document.getElementsByClassName('tell-tur-cms')[0];
  if (pageContent) {
    let links = pageContent.getElementsByTagName('a');
    [].forEach.call(links, link => {
      if (link.host !== window.location.host || /\..{3,4}$/.test(link.href) || link.target) {
        return;
      }

      link.onclick = e => {
        let nl = link.pathname;
        e.preventDefault();

        if (link.hash && link.pathname === window.location.pathname) {
          window.location.hash = link.hash;

          const elem = document.getElementById(link.hash.substr(1));
          if (elem) {
            elem.setAttribute('open', true);
            elem.scrollIntoView();
            window.scrollBy({
              top: -50,
              left: 0
            });
          }
          return;
        }

        if (link.pathname.indexOf('/') !== 0) {
          nl += '/' + nl;
        }
        history.push(nl);
      };
    });
  }
};

export { getPath, fixLinks };
