import React from "react";
import { withRouter } from "react-router-dom";
import { Articles, ensureArticleModuleConfig } from "@avinet/react-article-module";
import { translate } from "react-translate";
import PropTypes from "prop-types";
import PopupBox from "../components/PopupBox";
import Title from "../components/Title";
import Icon from "../components/Icon";
import TellturArticleListComponent from "../components/TellturArticleListComponent";
import LazyLoad from "../lib/lazyload";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateMapHistory } from "../redux/mapHistory";
import { provideMapState, olUtils, MapPadding } from "@avinet/react-openlayers";
import StiLayer from "../components/Map/StiLayer";
import "./ArticleView.scss";

const mapDispatchToProps = dispatch => {
  return {
    updateMapHistory: bindActionCreators(updateMapHistory, dispatch)
  };
};

const mapStateToProps = state => ({
  lastMapCenter: state.mapHistory.lastMapCenter,
  lastZoomLevel: state.mapHistory.lastZoomLevel
});

class ArticleView extends React.Component {
  constructor() {
    super();
    this.onSourceSelect = this.onSourceSelect.bind(this);
    this.onArticleOpen = this.onArticleOpen.bind(this);
    this.onTipBoxVisibilityChange = this.onTipBoxVisibilityChange.bind(this);

    this.state = {
      showTipBox: true,
      tmpHideTipBox: false
    };
  }

  componentWillMount() {
    const tipBoxDeactivated = localStorage.getItem("telltur_tipboxdeactivated");
    const tipboxTimestamp = localStorage.getItem("telltur_tipboxtimestamp");

    if (tipBoxDeactivated) {
      this.setState({
        showTipBox: false
      });
    }

    if (tipboxTimestamp) {
      const diff = Math.abs(Date.now() - tipBoxDeactivated);
      const diffDays = Math.floor(diff / (1000 * 3600 * 24));
      if (diffDays > 0) {
        this.setState({
          tmpHideTipBox: true
        });
      }
    }
  }

  componentDidMount() {
    const { lastMapCenter, lastZoomLevel } = this.props;
    this.lazyLoad = new LazyLoad({
      threshold: 500,
      container:
        window.innerWidth < 769 ? window : document.getElementById("article-view--scrollPane"),
      elements_selector: ".article-entry img"
    });
    if (lastMapCenter && lastZoomLevel) {
      const mapCenter = olUtils.createPoint(lastMapCenter);
      this.props.fitViewAndZoom(mapCenter, lastZoomLevel);
    }
  }

  componentWillUnmount() {
    this.lazyLoad.destroy();
    const { mapCenter, mapZoom } = this.props;
    this.props.updateMapHistory(mapZoom, mapCenter);
  }

  onSourceSelect(sources) {
    const { history } = this.props;
    const baseUrl = "/alleturmaal";
    history.push(baseUrl + "/" + sources.join("+"));
  }

  onArticleOpen(article) {
    const {
      history,
      match: {
        params: { activeSources }
      }
    } = this.props;
    const baseUrl = "/turmaal";
    history.push(baseUrl + "/" + article.id.replace("_", "/"), {
      activeSources
    });
    window.scroll(0, 0);
  }

  onTipBoxVisibilityChange(neverShowAgain) {
    if (neverShowAgain) {
      localStorage.setItem("telltur_tipboxdeactivated", true);
    } else {
      localStorage.removeItem("telltur_tipboxdeactivated");
      localStorage.setItem("telltur_tipboxtimestamp", Date.now());
    }
    this.setState({ showTipBox: !this.state.showTipBox });
  }

  get activeSources() {
    let {
      sources,
      match: {
        params: { activeSources }
      }
    } = this.props;

    activeSources = activeSources ? activeSources.split("+") : [];

    if (!activeSources.length) {
      activeSources = sources
        .filter(s => {
          return s.pressed && s.dataIndex;
        })
        .map(item => item.dataIndex);
    }

    return activeSources;
  }

  render() {
    const { t } = this.props;
    const { showTipBox, tmpHideTipBox } = this.state;
    const checkBoxChecked = localStorage.getItem("telltur_tipboxdeactivated");
    const activeSources = this.activeSources;

    return (
      <div className="article-view--container" id="scrollPane">
        <MapPadding padding={[0, 0, 0, 0]} />
        <Title title={t("Title")} />
        {showTipBox && !tmpHideTipBox && (
          <PopupBox
            onClose={this.onTipBoxVisibilityChange}
            closeBtnText={t("closeTipBox")}
            checkBoxText={t("dontshowTipBox")}
            checkBoxChecked={!!checkBoxChecked}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: t("tipboxContent").replace(
                  "{0}",
                  activeSources.length === 1
                    ? t("sourceselectorText1")
                    : t("sourceselectorText2").replace("{0}", activeSources.length)
                )
              }}
            />
          </PopupBox>
        )}
        <div className="buttons">
          <button
            className="article-view--tip-btn"
            onClick={() => this.setState({ showTipBox: true, tmpHideTipBox: false })}
          >
            <Icon name="info" />
          </button>
        </div>
        <div className="article-view--content" id="article-view--scrollPane">
          <Articles
            activeSources={activeSources}
            onContentChange={() => this.lazyLoad.update()}
            onSourceSelect={this.onSourceSelect}
            onArticleOpen={this.onArticleOpen}
            sourceSelectorPlaceholder={count =>
              count === 1
                ? t("sourceselectorText1")
                : t("sourceselectorText2").replace("{0}", count)
            }
            articleComponent={TellturArticleListComponent}
            useHoverStyle
            useActiveStyle
            articleLimit={20}
          />
        </div>
        <StiLayer />
      </div>
    );
  }
}

ArticleView.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  sources: PropTypes.array.isRequired,
  mapCenter: PropTypes.array.isRequired,
  mapZoom: PropTypes.number.isRequired,
  fitViewAndZoom: PropTypes.func.isRequired,
  lastMapCenter: PropTypes.array,
  lastZoomLevel: PropTypes.number,
  updateMapHistory: PropTypes.func.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(translate("ArticleView")(provideMapState(ensureArticleModuleConfig(ArticleView)))));
