import React from "react";
import Icon from "./Icon";

import parkingIcon from "../static/parking_blue.svg";
import parkingChargIconMap from "../static/p_charger_map.svg";

import "./Parking.scss";

const Parking = ({ title, description, type, onClose }) => {
  return (
    <div className="telltur-parking">
      <img src={type === 55 ? parkingIcon : parkingChargIconMap} alt="" />
      <div className="content">
        <p className="title">{title}</p>
        <p>{description}</p>
      </div>
      <button className="btn default-btn close-btn" onClick={() => onClose()}>
        <Icon name="cross" />
      </button>
    </div>
  );
};

export default Parking;
