import React from "react";
import { translate } from "react-translate";
import { withRouter, Link } from "react-router-dom";
import PropTypes from "prop-types";
import { ChangePasswordForm } from "@avinet/react-adaptive-auth";
import Title from "./Title";
import "./ChangePasswordPage.scss";

class ChangePasswordPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: "",
      passwordChanged: false
    };

    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  handleCancel() {
    this.props.history.goBack();
  }

  handlePasswordChange() {
    this.setState({
      error: null,
      passwordChanged: true
    });
  }

  render() {
    const { t, page, baseUrl } = this.props;
    return (
      <div className="tell-tur-page">
        <Title title={page.title} />
        <h1>{page.title}</h1>
        {!this.state.passwordChanged && (
          <ChangePasswordForm
            baseUrl={baseUrl}
            showLabels
            submitText={t("SaveNewPassword")}
            cancelText={t("Cancel")}
            errorText={t("CouldNotChangePassword")}
            currentPasswordText={t("CurrentPassword")}
            newPasswordText={t("NewPassword")}
            repeatPasswordText={t("RepeatPassword")}
            onPasswordChange={this.handlePasswordChange}
            onCancel={this.handleCancel}
          />
        )}
        {this.state.passwordChanged && (
          <div className="change-password-form--info-text">
            <p>{t("PasswordChanged")}</p>
            <Link to="/minprofil">{t("GoBack")}</Link>
          </div>
        )}
      </div>
    );
  }
}

ChangePasswordPage.propTypes = {
  history: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  page: PropTypes.object,
  baseUrl: PropTypes.string
};

export default withRouter(translate("ChangePasswordPage")(ChangePasswordPage));
