import React from 'react';
import PropTypes from 'prop-types';
import NavBar from '../containers/NavBar';
import Footer from '../components/Footer';
import './IndexLayout.scss';

class IndexLayout extends React.Component {
  componentDidMount () {
    document.getElementsByClassName('react-openlayers--map')[0].classList.add('hide-map');
  }

  render () {
    const { children } = this.props;
    return (
      <div className='index-layout--root'>
        <NavBar />
        <div className='index-layout--scroll-container'>
          <div className='index-layout--components'>{children}</div>
          <Footer />
        </div>
      </div>
    );
  }
}

IndexLayout.propTypes = {
  children: PropTypes.element
};

export default IndexLayout;
