import request from "superagent";
import { getSessionToken, authReducerActions } from "@avinet/react-adaptive-auth";
import { createAction, handleActions } from "redux-actions";
import { olUtils } from "@avinet/react-openlayers";

// ------------------------------------
// Constants
// ------------------------------------
export const SAVING_DIGI_THEME_RECORD = "SAVING_DIGI_THEME_RECORD";
export const LOADING_DIGI_THEME_RECORDS = "LOADING_ DIGI_THEME_RECORDS";
export const SAVE_SUCCESS_DIGI_THEME_RECORD = "SAVE_SUCCESS_DIGI_THEME_RECORD";
export const SAVE_FAILED_DIGI_THEME_RECORD = "SAVE_FAILED_DIGI_THEME_RECORD";
export const READ_FAILED_DIGI_THEME_RECORDS = "READ_FAILED_DIGI_THEME_RECORDS";
export const READ_SUCCESS_DIGI_THEME_RECORDS = "READ_SUCCESS_DIGI_THEME_RECORDS";
export const READ_OBJECT_SUCCESS_DIGI_THEME = "READ_OBJECT_SUCCESS_DIGI_THEME";
export const DELETING_DIGI_THEME_RECORD = "DELETING_DIGI_THEME_RECORD";
export const DELETE_DIGI_THEME_RECORD_SUCCESS = "DELETE_DIGI_THEME_RECORD_SUCCESS";
export const DELETE_DIGI_THEME_RECORD_FAILED = "DELETE_DIGI_THEME_RECORD_FAILED";
export const RESET_DIGI_THEME_REDUCER = "RESET_DIGI_THEME_REDUCER";

// ------------------------------------
// Actions
// ------------------------------------
const saving = createAction(SAVING_DIGI_THEME_RECORD);

const saveFailed = createAction(SAVE_FAILED_DIGI_THEME_RECORD, error => ({
  error
}));

const saveSuccess = createAction(SAVE_SUCCESS_DIGI_THEME_RECORD, data => ({
  data
}));

const loading = createAction(LOADING_DIGI_THEME_RECORDS);

const readSuccess = createAction(READ_SUCCESS_DIGI_THEME_RECORDS, (records, total) => ({
  records,
  total
}));

const readObjectSuccess = createAction(READ_OBJECT_SUCCESS_DIGI_THEME, record => ({
  record
}));

const readFailed = createAction(READ_FAILED_DIGI_THEME_RECORDS, error => ({
  error
}));

const deleting = createAction(DELETING_DIGI_THEME_RECORD);

const deleteSuccess = createAction(DELETE_DIGI_THEME_RECORD_SUCCESS, data => ({
  data
}));

const deleteFailed = createAction(DELETE_DIGI_THEME_RECORD_FAILED, data => ({
  data
}));

const resetDigiTheme = () => ({ type: RESET_DIGI_THEME_REDUCER });
// ------------------------------------
// Action creators
// ------------------------------------
const save = (data, srid) => {
  return (dispatch, getState, getReducerState) => {
    dispatch(saving());

    request
      .post(getReducerState().adaptiveUrl + "WebServices/client/DataView.asmx/Save")
      .send({
        data,
        theme_uuid: getReducerState().themeUuid
      })
      .set("X-Adaptive-Srid", srid)
      .set("Accept", "application/json")
      .set("gm_session_id", getSessionToken())
      .then(res => res.body)
      .then(res => {
        res = res.d;

        if (res.sessionExpired) {
          dispatch(authReducerActions.sessionExpired());
        }

        if (!res.success) {
          dispatch(saveFailed({ code: "SAVE_FAILED", message: res.exception.message }));
          return;
        }

        dispatch(saveSuccess(res.data[0].value));
      })
      .catch(e => {
        dispatch(saveFailed(e || { message: "saveFailed" }));
      });
  };
};

const deleteRecord = id => {
  return (dispatch, getState, getReducerState) => {
    dispatch(deleting());

    request
      .post(getReducerState().adaptiveUrl + "WebServices/client/DataView.asmx/Delete")
      .send({
        id,
        theme_uuid: getReducerState().themeUuid
      })
      .set("Accept", "application/json")
      .set("gm_session_id", getSessionToken())
      .then(res => res.body)
      .then(res => {
        res = res.d;

        if (res.sessionExpired) {
          dispatch(authReducerActions.sessionExpired());
        }

        if (!res.success) {
          dispatch(
            deleteFailed({
              code: "DELETE_FAILED",
              message: res.exception.message
            })
          );
          return;
        }

        dispatch(deleteSuccess());
      })

      .catch(e => {
        dispatch(deleteFailed(e || { message: "deleteFailed" }));
      });
  };
};

const readChildren = (columns, filter, fkColumn, fkColumnId, srid, start, limit, extraParams) => {
  return (dispatch, getState, getReducerState) => {
    dispatch(loading());

    readAny(
      {
        theme_uuid: getReducerState().themeUuid,
        filter,
        fk_column: fkColumn,
        fk_column_id: fkColumnId,
        columns,
        srid,
        start,
        limit,
        extraParams
      },
      srid,
      false,
      dispatch,
      getReducerState
    );
  };
};

const readAny = (data, srid, readObject = false, dispatch, getReducerState) => {
  request
    .post(getReducerState().adaptiveUrl + "WebServices/client/DataView.asmx/ReadAny")
    .send({
      request: data
    })
    .set("Accept", "application/json")
    .set("gm_session_id", getSessionToken())
    .set("X-Adaptive-SRID", srid)
    .then(res => res.body)
    .then(res => {
      res = res.d;

      if (res.sessionExpired) {
        dispatch(authReducerActions.sessionExpired());
      }

      if (!res.success) {
        dispatch(readFailed(res.exception.message));
        return;
      }
      if (readObject) {
        if (res.records.length !== 1) {
          dispatch(
            readFailed({
              code: "NO_RECORD_FOUND",
              message: "Kunne ikke finne record"
            })
          );
        } else {
          dispatch(readObjectSuccess(res.records[0], res.total));
        }
        return;
      }
      dispatch(readSuccess(res.records, res.total));
    })
    .catch(e => {
      dispatch(readFailed(e || { message: "Kunne ikke lese data" }));
    });
};

const readObject = (id, columns, srid, only_owned = false) => {
  return (dispatch, getState, getReducerState) => {
    dispatch(loading());

    readAny(
      {
        theme_uuid: getReducerState().themeUuid,
        object_id: id,
        columns,
        only_owned
      },
      srid,
      true,
      dispatch,
      getReducerState
    );
  };
};

const readOwned = (columns, filter, srid, start, limit, extraParams) => {
  return (dispatch, getState, getReducerState) => {
    dispatch(loading());

    readAny(
      {
        theme_uuid: getReducerState().themeUuid,
        filter,
        columns,
        srid,
        start,
        limit,
        extraParams,
        only_owned: true
      },
      srid,
      false,
      dispatch,
      getReducerState
    );
  };
};

const readFiltered = (columns, filter, srid, start, limit, extraParams) => {
  return (dispatch, getState, getReducerState) => {
    dispatch(loading());

    readAny(
      {
        theme_uuid: getReducerState().themeUuid,
        filter,
        columns,
        srid,
        start,
        limit,
        extraParams
      },
      srid,
      false,
      dispatch,
      getReducerState
    );
  };
};

export const actions = {
  save,
  deleteRecord,
  readFiltered,
  readObject,
  readOwned,
  readChildren,
  resetDigiTheme
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  loading: false,
  saving: false,
  deleting: false,
  error: undefined,
  records: [],
  record: undefined
};

export default (key, adaptiveUrl, themeUuid) =>
  handleActions(
    {
      [key + "@@" + SAVING_DIGI_THEME_RECORD]: (state, { payload }) => {
        return Object.assign({}, state, {
          saving: true,
          error: undefined
        });
      },
      [key + "@@" + LOADING_DIGI_THEME_RECORDS]: (state, { payload }) => {
        return Object.assign({}, state, {
          loading: true,
          error: undefined
        });
      },
      [key + "@@" + SAVE_SUCCESS_DIGI_THEME_RECORD]: (state, { payload }) => {
        if (payload.data.geom_wkt) {
          payload.data.olFeature = olUtils.createFeatureFromWkt(
            payload.data.geom_wkt,
            payload.data
          );
        }
        return Object.assign({}, state, {
          saving: false,
          record: payload.data
        });
      },
      [key + "@@" + SAVE_FAILED_DIGI_THEME_RECORD]: (state, { payload }) =>
        Object.assign({}, state, {
          saving: false,
          error: payload.error
        }),
      [key + "@@" + READ_FAILED_DIGI_THEME_RECORDS]: (state, { payload }) =>
        Object.assign({}, state, {
          loading: false,
          error: payload.error
        }),
      [key + "@@" + READ_SUCCESS_DIGI_THEME_RECORDS]: (state, { payload }) => {
        const { records, total } = payload;
        if (records.length && records[0].geom_wkt) {
          records.forEach(r => {
            r.olFeature = olUtils.createFeatureFromWkt(r.geom_wkt, r);
          });
        }
        return Object.assign({}, state, {
          loading: false,
          records,
          total
        });
      },
      [key + "@@" + READ_OBJECT_SUCCESS_DIGI_THEME]: (state, { payload }) => {
        const { record } = payload;
        if (record.geom_wkt) {
          record.olFeature = olUtils.createFeatureFromWkt(record.geom_wkt, record);
        }
        return Object.assign({}, state, {
          record: record,
          loading: false
        });
      },
      [key + "@@" + DELETING_DIGI_THEME_RECORD]: (state, { payload }) => {
        return Object.assign({}, state, {
          deleting: true,
          error: undefined
        });
      },
      [key + "@@" + DELETE_DIGI_THEME_RECORD_SUCCESS]: (state, { payload }) => {
        return Object.assign({}, state, {
          deleting: false,
          error: undefined
        });
      },
      [key + "@@" + DELETE_DIGI_THEME_RECORD_FAILED]: (state, { payload }) => {
        return Object.assign({}, state, {
          deleting: false,
          error: payload.error
        });
      },
      [key + "@@" + RESET_DIGI_THEME_REDUCER]: (state, { payload }) => {
        return Object.assign({}, state, initialState);
      }
    },
    { ...initialState, adaptiveUrl, themeUuid }
  );
