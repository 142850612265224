import React from "react";
import PropTypes from "prop-types";
import Icon from "../../components/Icon";
import ResultList from "../../components/Results/ResultList";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { translate } from "react-translate";
import { withRouter, Link } from "react-router-dom";
import {
  readCompetitionResults,
  readUniqueJoinedCompetitions,
} from "../../redux/competition";

import "./CompetitionResults.scss";
import { fixDate } from "../../utils/date";

const mapStateToProps = (state) => ({
  uniqueJoinedCompetitions: state.joinCompetitionReducer.competitions,
  competitionResults: state.joinCompetitionReducer.results,
  competitionResultsLoading: state.joinCompetitionReducer.loading,
});
const mapDispatchToProps = (dispatch) => {
  return {
    readUniqueJoinedCompetitions: bindActionCreators(
      readUniqueJoinedCompetitions,
      dispatch
    ),
    readCompetitionResults: bindActionCreators(
      readCompetitionResults,
      dispatch
    ),
  };
};
class CompetitionResults extends React.Component {
  constructor() {
    super();
    this.state = {
      index: null,
    };
  }
  componentDidMount() {
    this.props.readUniqueJoinedCompetitions();
  }

  getDate(start, end) {
    const { t } = this.props;
    const fixedStartDate = fixDate(start);
    const fixedEndDate = fixDate(end);
    const now = new Date();

    if (Math.sign(fixedEndDate) === -1) {
      return `${t("Started")}: ${fixedStartDate.toLocaleDateString(
        t("locale")
      )}`;
    } else if (fixedEndDate.setHours(0, 0, 0, 0) < now.setHours(0, 0, 0, 0)) {
      return "";
    } else {
      return `${t("Started")}: ${fixedStartDate.toLocaleDateString(
        t("locale")
      )} - ${t("Ends")}: ${fixedEndDate.toLocaleDateString(t("locale"))}`;
    }
  }

  render() {
    const {
      uniqueJoinedCompetitions,
      readCompetitionResults,
      competitionResults,
      competitionResultsLoading,
      member,
      t,
    } = this.props;
    const { index } = this.state;

    return (
      <div className="tell-tur__competition-results--wrapper">
        <h1>{t("Title")}</h1>
        <p>
          {t("CompetitionDescription")}
          <Link to="/friluftsrad">{t("LinkToFriluftsrad")}</Link>.
        </p>
        <div className="competition-results__wrapper--competitions-list">
          {uniqueJoinedCompetitions && uniqueJoinedCompetitions.length > 0 ? (
            uniqueJoinedCompetitions.map((competition, idx) => (
              <div
                key={idx}
                className="competitions-list__competition--container"
              >
                <div
                  className={[
                    "container__title--button",
                    new Date().setHours(0, 0, 0, 0) <
                      new Date(fixDate(competition.end_date)).setHours(
                        0,
                        0,
                        0,
                        0
                      ) || Math.sign(fixDate(competition.end_date)) === -1
                      ? "ongoing"
                      : "",
                  ].join(" ")}
                >
                  <span
                    className={[
                      "content__label",
                      index === idx ? "active" : "",
                    ].join(" ")}
                  >
                    {competition.title}
                    <br />
                    <span className="content__label-date">
                      {this.getDate(
                        competition.start_date,
                        competition.end_date
                      )}
                    </span>
                  </span>
                  <button
                    onClick={() => {
                      if (idx === index) {
                        this.setState({ index: undefined });
                      } else {
                        this.setState({ index: idx });
                        readCompetitionResults(competition.competition_id);
                      }
                    }}
                  >
                    <Icon name={index === idx ? "chevronUp" : "chevronDown"} />
                  </button>
                </div>
                <div
                  className={[
                    "competition__container--content",
                    index === idx ? "active" : "",
                  ].join(" ")}
                >
                  {index === idx && (
                    <ResultList
                      results={competitionResults}
                      displayProps={["nick_name", "points"]}
                      headers={["#", t("Nickname"), t("Points")]}
                      loading={competitionResultsLoading}
                      member={member}
                    />
                  )}
                </div>
              </div>
            ))
          ) : (
            <div className="competition-results--no-joined-competitions">
              <p>{t("NoUniqueJoinedCompetitions")}</p>
            </div>
          )}
        </div>
      </div>
    );
  }
}
CompetitionResults.propTypes = {
  readCompetitionResults: PropTypes.func.isRequired,
  readUniqueJoinedCompetitions: PropTypes.func.isRequired,
  uniqueJoinedCompetitions: PropTypes.array,
  competitionResults: PropTypes.array,
  competitionResultsLoading: PropTypes.bool,
  member: PropTypes.object,
  t: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(translate("CompetitionResults")(CompetitionResults)));
