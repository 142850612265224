import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
// import logger from 'redux-logger';
import makeRootReducer from './reducers';

export default (initalState = {}) => {
  // Middleware Configuration
  const middleware = [thunk];

  // eslint-disable-next-line
  if (process.env.NODE_ENV !== 'production') {
    // middleware.push(logger);
  }
  // Store Enhancers
  const enhancers = [];
  if (window.__REDUX_DEVTOOLS_EXTENSION__) {
    enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__());
  }

  let composeEnhancers = compose;

  // Store Instatiation and HMR Setup
  const store = createStore(
    makeRootReducer(),
    initalState,
    composeEnhancers(applyMiddleware(...middleware), ...enhancers)
  );

  store.asyncReducers = {};

  // eslint-disable-next-line
  if (module.hot) {
    // eslint-disable-next-line
    module.hot.accept('./reducers', () => {
      // eslint-disable-next-line
      const reducers = require('./reducers').default;
      store.replaceReducer(reducers(store.asyncReducers));
    });
  }
  return store;
};
