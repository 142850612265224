import React from "react";
import PropTypes from "prop-types";
import Title from "../components/Title";
import Competitions from "../containers/competition/Competitions";
import "./FriluftsradPage.scss";

class FriluftsradPage extends React.Component {
  render() {
    const {
      page: { content, title, data },
      location
    } = this.props;

    const isFriluftsradPage = location.pathname.indexOf("friluftsrad") !== -1;
    // const isFriluftsradPage = location.pathname.split('/').length === 3;

    return (
      <div className="friluftsrad-page--wrapper">
        <span className="friluftsrad-page--main">
          <Title title={title} />
          <div className="tell-tur-cms">
            <div
              className="telltur--friluftsrad-page"
              dangerouslySetInnerHTML={{
                __html: content
              }}
            />
          </div>
          <div className="tell-tur--div-components" />
        </span>
        {isFriluftsradPage && data && (
          <aside className="friluftsrad-page--aside">
            <Competitions
              friluftsradUuid={data.friluftsrad_uuid}
              friluftsrad={title}
            />
          </aside>
        )}
      </div>
    );
  }
}

FriluftsradPage.propTypes = {
  page: PropTypes.object,
  location: PropTypes.object
};

export default FriluftsradPage;
