import request from 'superagent';

export const post = (url, headers, data, callback) => {
  request
    .post(url)
    .send(data)
    .set(headers)
    .end((err, response) => {
      callback(err, response);
    });
};
