import React from "react";
import PropTypes from "prop-types";
import "./Icon.scss";

const icons = {
  search: (
    <svg viewBox="0 0 32 32">
      <path d="M31.008 27.231l-7.58-6.447c-0.784-0.705-1.622-1.029-2.299-0.998 1.789-2.096 2.87-4.815 2.87-7.787 0-6.627-5.373-12-12-12s-12 5.373-12 12 5.373 12 12 12c2.972 0 5.691-1.081 7.787-2.87-0.031 0.677 0.293 1.515 0.998 2.299l6.447 7.58c1.104 1.226 2.907 1.33 4.007 0.23s0.997-2.903-0.23-4.007zM12 20c-4.418 0-8-3.582-8-8s3.582-8 8-8 8 3.582 8 8-3.582 8-8 8z" />
    </svg>
  ),
  pencil: (
    <svg viewBox="0 0 32 32">
      <path d="M27 0c2.761 0 5 2.239 5 5 0 1.126-0.372 2.164-1 3l-2 2-7-7 2-2c0.836-0.628 1.874-1 3-1zM2 23l-2 9 9-2 18.5-18.5-7-7-18.5 18.5zM22.362 11.362l-14 14-1.724-1.724 14-14 1.724 1.724z" />
    </svg>
  ),
  unlock: (
    <svg viewBox="0 0 21 18.667">
      <path d="M15.44,0A5.578,5.578,0,0,0,9.917,5.6v2.57H1.75A1.75,1.75,0,0,0,0,9.917v7a1.75,1.75,0,0,0,1.75,1.75H14.583a1.75,1.75,0,0,0,1.75-1.75v-7a1.75,1.75,0,0,0-1.75-1.75h-1.75V5.574a2.625,2.625,0,1,1,5.25-.033V8.458a.873.873,0,0,0,.875.875h1.167A.873.873,0,0,0,21,8.458V5.542A5.548,5.548,0,0,0,15.44,0Z" />
    </svg>
  ),
  checkmark: (
    <svg viewBox="0 0 32 32">
      <path d="M27 4l-15 15-7-7-5 5 12 12 20-20z" />
    </svg>
  ),
  userplus: (
    <svg viewBox="0 0 32 32">
      <path d="M12 23c0-4.726 2.996-8.765 7.189-10.319 0.509-1.142 0.811-2.411 0.811-3.681 0-4.971 0-9-6-9s-6 4.029-6 9c0 3.096 1.797 6.191 4 7.432v1.649c-6.784 0.555-12 3.888-12 7.918h12.416c-0.271-0.954-0.416-1.96-0.416-3z" />
      <path d="M23 14c-4.971 0-9 4.029-9 9s4.029 9 9 9c4.971 0 9-4.029 9-9s-4.029-9-9-9zM28 24h-4v4h-2v-4h-4v-2h4v-4h2v4h4v2z" />
    </svg>
  ),
  userminus: (
    <svg viewBox="0 0 32 32">
      <path d="M12 23c0-4.726 2.996-8.765 7.189-10.319 0.509-1.142 0.811-2.411 0.811-3.681 0-4.971 0-9-6-9s-6 4.029-6 9c0 3.096 1.797 6.191 4 7.432v1.649c-6.784 0.555-12 3.888-12 7.918h12.416c-0.271-0.954-0.416-1.96-0.416-3z" />
      <path d="M23 14c-4.971 0-9 4.029-9 9s4.029 9 9 9c4.971 0 9-4.029 9-9s-4.029-9-9-9zM28 24h-10v-2h10v2z" />
    </svg>
  ),
  usersGroup: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="13.364"
      viewBox="0 0 21 13.364"
    >
      <path
        d="M15.818,13.227a2.864,2.864,0,1,0-2.864-2.864A2.852,2.852,0,0,0,15.818,13.227Zm-7.636,0a2.864,2.864,0,1,0-2.864-2.864A2.852,2.852,0,0,0,8.182,13.227Zm0,1.909c-2.224,0-6.682,1.117-6.682,3.341v2.386H14.864V18.477C14.864,16.253,10.406,15.136,8.182,15.136Zm7.636,0c-.277,0-.592.019-.926.048a4.028,4.028,0,0,1,1.88,3.293v2.386H22.5V18.477C22.5,16.253,18.042,15.136,15.818,15.136Z"
        transform="translate(-1.5 -7.5)"
      />
    </svg>
  ),
  trash: (
    <svg viewBox="0 0 16 18.286">
      <path d="M1.143,16.571a1.714,1.714,0,0,0,1.714,1.714H13.143a1.714,1.714,0,0,0,1.714-1.714v-12H1.143Zm9.714-9.143a.571.571,0,1,1,1.143,0v8a.571.571,0,0,1-1.143,0Zm-3.429,0a.571.571,0,1,1,1.143,0v8a.571.571,0,0,1-1.143,0ZM4,7.429a.571.571,0,1,1,1.143,0v8a.571.571,0,0,1-1.143,0ZM15.429,1.143H11.143L10.807.475A.857.857,0,0,0,10.039,0H5.957a.847.847,0,0,0-.764.475l-.336.668H.571A.571.571,0,0,0,0,1.714V2.857a.571.571,0,0,0,.571.571H15.429A.571.571,0,0,0,16,2.857V1.714A.571.571,0,0,0,15.429,1.143Z" />
    </svg>
  ),
  cross: (
    <svg viewBox="0 0 16 16">
      <path d="M13.957 3.457l-1.414-1.414-4.543 4.543-4.543-4.543-1.414 1.414 4.543 4.543-4.543 4.543 1.414 1.414 4.543-4.543 4.543 4.543 1.414-1.414-4.543-4.543z" />
    </svg>
  ),
  profile: (
    <svg viewBox="0 0 1000 1000">
      <path d="M670.3,574.2c82.9-55.2,137.6-149.4,137.6-256.4c0-170-137.8-307.8-307.8-307.8c-170,0-307.8,137.8-307.8,307.8c0,107,54.7,201.2,137.6,256.4C171.5,637.4,59,786.3,53.2,961.3c0,0-7.6,33.2,55.2,28c26.9-2.2,33.7-28,33.7-28c29.8-190.7,178.5-335.8,358-335.8c179.5,0,328.2,145.1,358,335.8c0,0,17.4,26.1,33.7,28c51.3,5.9,55.2-28,55.2-28C941.3,786.3,828.8,637.4,670.3,574.2z M500.1,541.6c-123.6,0-223.8-100.2-223.8-223.8c0-123.6,100.2-223.8,223.8-223.8C623.8,93.9,724,194.2,724,317.8C724,441.4,623.8,541.6,500.1,541.6z" />
    </svg>
  ),
  share: (
    <svg viewBox="0 0 32 32">
      <path d="M8 20c0 0 1.838-6 12-6v6l12-8-12-8v6c-8 0-12 4.99-12 10zM22 24h-18v-12h3.934c0.315-0.372 0.654-0.729 1.015-1.068 1.374-1.287 3.018-2.27 4.879-2.932h-13.827v20h26v-8.395l-4 2.667v1.728z" />
    </svg>
  ),
  facebook: (
    <svg viewBox="0 0 32 32">
      <path d="M29 0h-26c-1.65 0-3 1.35-3 3v26c0 1.65 1.35 3 3 3h13v-14h-4v-4h4v-2c0-3.306 2.694-6 6-6h4v4h-4c-1.1 0-2 0.9-2 2v2h6l-1 4h-5v14h9c1.65 0 3-1.35 3-3v-26c0-1.65-1.35-3-3-3z" />
    </svg>
  ),
  load: (
    <svg viewBox="0 0 32 32">
      <path d="M12 4c0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.209-1.791 4-4 4s-4-1.791-4-4zM20.485 7.515c0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.209-1.791 4-4 4s-4-1.791-4-4zM26 16c0-1.105 0.895-2 2-2s2 0.895 2 2c0 1.105-0.895 2-2 2s-2-0.895-2-2zM22.485 24.485c0-1.105 0.895-2 2-2s2 0.895 2 2c0 1.105-0.895 2-2 2s-2-0.895-2-2zM14 28c0 0 0 0 0 0 0-1.105 0.895-2 2-2s2 0.895 2 2c0 0 0 0 0 0 0 1.105-0.895 2-2 2s-2-0.895-2-2zM5.515 24.485c0 0 0 0 0 0 0-1.105 0.895-2 2-2s2 0.895 2 2c0 0 0 0 0 0 0 1.105-0.895 2-2 2s-2-0.895-2-2zM4.515 7.515c0 0 0 0 0 0 0-1.657 1.343-3 3-3s3 1.343 3 3c0 0 0 0 0 0 0 1.657-1.343 3-3 3s-3-1.343-3-3zM1.75 16c0-1.243 1.007-2.25 2.25-2.25s2.25 1.007 2.25 2.25c0 1.243-1.007 2.25-2.25 2.25s-2.25-1.007-2.25-2.25z" />
    </svg>
  ),
  chevronUp: (
    <svg viewBox="0 0 28 28">
      <path d="M26.297 20.797l-2.594 2.578c-0.391 0.391-1.016 0.391-1.406 0l-8.297-8.297-8.297 8.297c-0.391 0.391-1.016 0.391-1.406 0l-2.594-2.578c-0.391-0.391-0.391-1.031 0-1.422l11.594-11.578c0.391-0.391 1.016-0.391 1.406 0l11.594 11.578c0.391 0.391 0.391 1.031 0 1.422z" />
    </svg>
  ),
  chevronDown: (
    <svg viewBox="0 0 28 28">
      <path d="M26.297 12.625l-11.594 11.578c-0.391 0.391-1.016 0.391-1.406 0l-11.594-11.578c-0.391-0.391-0.391-1.031 0-1.422l2.594-2.578c0.391-0.391 1.016-0.391 1.406 0l8.297 8.297 8.297-8.297c0.391-0.391 1.016-0.391 1.406 0l2.594 2.578c0.391 0.391 0.391 1.031 0 1.422z" />
    </svg>
  ),
  chevronRight: (
    <svg viewBox="0 0 16 16">
      <path d="M5.5 0l-2 2 6 6-6 6 2 2 8-8-8-8z" />
    </svg>
  ),
  chevronLeft: (
    <svg viewBox="0 0 16 16">
      <path d="M10.5 16l2-2-6-6 6-6-2-2-8 8 8 8z" />
    </svg>
  ),
  info: (
    <svg viewBox="0 0 16 16">
      <path d="M7 4.75c0-0.412 0.338-0.75 0.75-0.75h0.5c0.412 0 0.75 0.338 0.75 0.75v0.5c0 0.412-0.338 0.75-0.75 0.75h-0.5c-0.412 0-0.75-0.338-0.75-0.75v-0.5z" />
      <path d="M10 12h-4v-1h1v-3h-1v-1h3v4h1z" />
      <path d="M8 0c-4.418 0-8 3.582-8 8s3.582 8 8 8 8-3.582 8-8-3.582-8-8-8zM8 14.5c-3.59 0-6.5-2.91-6.5-6.5s2.91-6.5 6.5-6.5 6.5 2.91 6.5 6.5-2.91 6.5-6.5 6.5z" />
    </svg>
  ),
  checkmarkInCircle: (
    <svg viewBox="0 0 24 24">
      <path d="M21 11.080v0.92c-0.001 2.485-1.009 4.733-2.64 6.362s-3.88 2.634-6.365 2.632-4.734-1.009-6.362-2.64-2.634-3.879-2.633-6.365 1.009-4.733 2.64-6.362 3.88-2.634 6.365-2.633c1.33 0.001 2.586 0.289 3.649 0.775 0.502 0.23 1.096 0.008 1.325-0.494s0.008-1.096-0.494-1.325c-1.327-0.606-2.866-0.955-4.479-0.956-3.037-0.002-5.789 1.229-7.78 3.217s-3.224 4.74-3.226 7.777 1.229 5.789 3.217 7.78 4.739 3.225 7.776 3.226 5.789-1.229 7.78-3.217 3.225-4.739 3.227-7.777v-0.92c0-0.552-0.448-1-1-1s-1 0.448-1 1zM21.293 3.293l-9.293 9.302-2.293-2.292c-0.391-0.391-1.024-0.391-1.414 0s-0.391 1.024 0 1.414l3 3c0.391 0.391 1.024 0.39 1.415 0l10-10.010c0.39-0.391 0.39-1.024-0.001-1.414s-1.024-0.39-1.414 0.001z" />
    </svg>
  ),
  map: (
    <svg viewBox="0 0 32 32">
      <path d="M21 6l-10-4-11 4v24l11-4 10 4 11-4v-24l-11 4zM12 4.554l8 3.2v19.692l-8-3.2v-19.692zM2 7.401l8-2.909v19.744l-8 2.909v-19.744zM30 24.599l-8 2.909v-19.744l8-2.909v19.744z" />
    </svg>
  ),
  medal: (
    <svg viewBox="0 0 32 32">
      <path d="M17.062 11.637l4.298-10.637 5.563 2.248-4.367 10.808c-1.502-1.317-3.401-2.191-5.494-2.419v0 0zM9.369 14.062l-4.369-10.814 5.563-2.248 4.298 10.638c-2.093 0.23-3.991 1.106-5.492 2.424v0 0zM15.966 30.578c4.971 0 9-4.029 9-9s-4.029-9-9-9c-4.971 0-9 4.029-9 9s4.029 9 9 9v0 0zM15.966 24.27l-3.5 2.308 1.5-4-3-2h3.5l1.5-4 1.5 4h3.5l-3 2 1.5 4-3.5-2.308z" />
    </svg>
  ),
  print: (
    <svg viewBox="0 0 32 32">
      <path d="M8 2h16v4h-16v-4z"></path>
      <path d="M30 8h-28c-1.1 0-2 0.9-2 2v10c0 1.1 0.9 2 2 2h6v8h16v-8h6c1.1 0 2-0.9 2-2v-10c0-1.1-0.9-2-2-2zM4 14c-1.105 0-2-0.895-2-2s0.895-2 2-2 2 0.895 2 2-0.895 2-2 2zM22 28h-12v-10h12v10z"></path>
    </svg>
  )
};

const Icon = props => {
  const { name } = props;
  return <span className="icon">{icons[name]}</span>;
};

Icon.propTypes = {
  name: PropTypes.string.isRequired
};

export default Icon;
