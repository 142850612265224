import { useContext, useEffect, useState } from "react";
import request from "superagent";
import { olUtils } from "@avinet/react-openlayers";
import { ArticleModuleContext } from "@avinet/react-article-module";
import config from "../config/TellTur";

export default function useSearch({ query, sources, mapExtent, mapCenter }) {
  const { projCode } = useContext(ArticleModuleContext);
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);

  useEffect(() => {
    if (!query || !mapCenter) {
      setLoading(false);
      setResults([]);
      return;
    }

    setLoading(true);

    performIndexQuery(query, sources, mapExtent, projCode)
      .then(data => {
        setResults(data);
        setLoading(false);
      })
      .catch(e => {
        setResults([]);
        console.error("Failed to load search results", e);
      });
  }, [query, sources, mapExtent, mapCenter, projCode]);

  return { loading, results };
}

function performIndexQuery(query, sources, extent, projCode) {
  return new Promise((resolve, reject) => {
    extent = olUtils.transformExtent(extent, "EPSG:3857", "EPSG:4326");
    const bbox = {
      left: extent[0],
      bottom: extent[1],
      right: extent[2],
      top: extent[3]
    };

    const data = {
      q: query + "*",
      srsid: "4326",
      start: 0,
      limit: 100,
      bbox: bbox,
      layers: sources.map(s => s.value.layerId).join(",")
    };

    request
      .post(config.adaptiveUrl + "WebServices/search/SearchProxy.asmx/Search")
      .send({ request: data })
      .set("Accept", "application/json")
      .set("Content-Type", "application/json")
      .then(res => res.body)
      .then(res => {
        res = res.d;
        if (res.success) {
          const data = parseIndexerResponse(res.records, sources);
          resolve(data);
        } else {
          throw new Error(res.d.exception);
        }
      })
      .catch(e => {
        reject(e);
      });
  });
}

function parseIndexerResponse(records, sources) {
  return records
    .map(rec => {
      const { desc, elementType, geom, layerId, layerName, title, uid, values, relevance } = rec;

      const objId = uid.split(".").slice(2).join(".");
      const source = sources.find(s => s.value.layerId.toString() === rec.layerId);
      const id = source.dataIndex + "_" + objId;

      if (!geom) {
        return false;
      }
      const feature = olUtils.createFeatureFromWkt(geom, { color: source.config.fillColor });
      if (feature && feature.getGeometry())
        feature.getGeometry().transform("EPSG:4326", "EPSG:3857");

      return {
        id,
        uid,
        properties: {
          title,
          content: desc
            .replace(/^<b>(.*?)<\/b>/, "**$1**")
            .replace(/<[\s\S]*?>/gm, " ")
            .replace(/\s+/g, " ")
            .replace(/^\*\*(.*?)\*\*/, "<b>$1</b>")
        },
        geometry: {
          type: feature.getGeometry().getType(),
          coordinates: feature.getGeometry().getCoordinates()
        },
        olFeature: feature,
        elementType,
        layerId,
        layerName,
        values,
        source,
        relevance
      };
    })
    .filter(i => !!i && i.relevance > 20);
}
