import React from "react";
import { translate } from "react-translate";
import Select from "react-select";
import PropTypes from "prop-types";
import TranslatedServerError from "../TranslatedServerError";

const MemberList = props => {
  const {
    members,
    infoText,
    memberError,
    changeCurrentMember,
    t,
    selectedMemberId,
    hideTripCount,
    children,
    isSearchable
  } = props;

  return (
    <form className="tell-tur-form no-padding">
      <div
        className="form-control-infoText"
        dangerouslySetInnerHTML={{ __html: infoText }}
      />
      {memberError && <TranslatedServerError error={memberError} />}
      <span className="form-control-inlineFlex">
        <span className="tell-tur-form--control">
          <Select
            classNamePrefix="editable-search-dropdown"
            options={members}
            isSearchable={isSearchable}
            getOptionLabel={m =>
              hideTripCount
                ? m.nick_name
                : `${m.nick_name} (${m.trip_count} ${t("Trips").toLowerCase()})`
            }
            getOptionValue={m => m.id}
            value={
              members &&
              !!members.length &&
              members.find(m => m.id === selectedMemberId)
            }
            onChange={(di, action) => {
              if (action.action === "select-option") {
                changeCurrentMember(di.id);
              }
            }}
          />
        </span>
        {children}
      </span>
    </form>
  );
};

MemberList.propTypes = {
  members: PropTypes.array,
  infoText: PropTypes.string,
  memberError: PropTypes.object,
  changeCurrentMember: PropTypes.func,
  t: PropTypes.func,
  selectedMemberId: PropTypes.number.isRequired,
  hideTripCount: PropTypes.bool,
  children: PropTypes.element
};

export default translate("MemberList")(MemberList);
