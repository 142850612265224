import React from "react";
import PropTypes from "prop-types";
import Title from "../components/Title";
import LoadingIndicator from "../components/LoadingIndicator";
import ProfileValidator from "../components/ProfileValidator";
import IndexPage from "../components/IndexPage";
import scopedBindActionCreators from "../utils/scopedReducer";
import { bindActionCreators } from "redux";
import { pageActions } from "@avinet/react-adaptive-cms";
import { withRouter } from "react-router-dom";
import { translate } from "react-translate";
import { connect } from "react-redux";
import { getSessionToken } from "@avinet/react-adaptive-auth";
import { actions as digiThemeActions } from "../utils/digiTheme";
import { getPath } from "../utils/Path";
import "./PageView.scss";
import config from "../config/TellTur";

const mapStateToProps = state => ({
  competitions: state.competition.records,
  competitionsLoading: state.competition.loading,
  friluftsraad: state.readFriluftsraad.records,
  page: state.page.page,
  pageLoading: state.page.pageLoading,
  user: state.user.user,
  userLoading: state.user.loading
});

const mapDispatchToProps = dispatch => {
  return {
    loadPage: bindActionCreators(pageActions.loadPage, dispatch),
    readCompetitions: scopedBindActionCreators(
      digiThemeActions.readFiltered,
      dispatch,
      "competition"
    ),
    resetDigiTheme: scopedBindActionCreators(
      digiThemeActions.resetDigiTheme,
      dispatch,
      "competition"
    )
  };
};

class PageView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPath: ""
    };
  }
  componentDidMount() {
    const { loadPage, location, resetDigiTheme, user } = this.props;
    const path = getPath(location);
    this.setState({ currentPath: path });

    loadPage(path);

    if (!getSessionToken || !user) resetDigiTheme();

    if (user) {
      const mainMember = user.familymembers.find(f => f.is_main_familymember === true);
      this.readFriluftsraadCompetitions(mainMember);
    }
  }

  componentWillReceiveProps(nextProps) {
    const path = getPath(nextProps.location);
    if (path !== this.state.currentPath) {
      this.setState({ currentPath: path });
      nextProps.loadPage(path);
    }

    if (!this.props.user && nextProps.user && nextProps.user.familymembers) {
      const mainMember = nextProps.user.familymembers.find(f => f.is_main_familymember === true);

      this.readFriluftsraadCompetitions(mainMember);
    }
  }

  readFriluftsraadCompetitions(user) {
    const { readCompetitions } = this.props;
    if (!getSessionToken || !user) return;

    const friluftsraadUuid = user.selected_friluftsraad_uuid;

    readCompetitions(
      [
        "id",
        "title",
        "description",
        "image",
        "municipality",
        "year_of_birth_to",
        "year_of_birth_from",
        "start_date",
        "end_date"
      ],
      {
        filterColumns: [
          {
            name: "_acl",
            netType: "Guid[]",
            value: [friluftsraadUuid],
            comparisonOperator: "@>",
            logicalOperator: "AND"
          },
          {
            name: "show",
            netType: "boolean",
            value: true,
            comparisonOperator: "=",
            logicalOperator: "AND"
          }
        ],
        sortColums: [
          {
            name: "name"
          }
        ]
      },
      config.mapProjCode
    );
  }

  get getAssocietedFriluftsraadUri() {
    const { friluftsraad, user } = this.props;
    if (!getSessionToken || !user) return "/friluftsrad";

    const mainMember = user.familymembers.find(f => f.is_main_familymember === true);
    const myFr = friluftsraad.find(fr => fr.uuid === mainMember.selected_friluftsraad_uuid);

    if (!myFr) return "/friluftsrad";

    const myFrUri = myFr && myFr.uri;

    return `/friluftsrad/${myFrUri}`;
  }

  render() {
    let {
      page: { content, title },
      pageLoading,
      user,
      userLoading,
      competitions
    } = this.props;
    const { currentPath } = this.state;

    return (
      <div className="tell-tur">
        <Title title={title} />
        {pageLoading || userLoading ? (
          <div className="loader">
            <LoadingIndicator />
          </div>
        ) : (
          <div className="tell-tur-cms">
            {currentPath === "index" ? (
              <IndexPage
                competitions={competitions}
                friluftsraadHref={this.getAssocietedFriluftsraadUri}
                getAssocietedFriluftsraadUri={this.getAssocietedFriluftsraadUri}
              />
            ) : (
              <div dangerouslySetInnerHTML={{ __html: content }} />
            )}
          </div>
        )}
        {user && <ProfileValidator user={user} />}
      </div>
    );
  }
}

PageView.propTypes = {
  page: PropTypes.object,
  pageLoading: PropTypes.bool,
  loadPage: PropTypes.func,
  location: PropTypes.object,
  user: PropTypes.object,
  userLoading: PropTypes.bool,
  resetDigiTheme: PropTypes.func.isRequired,
  readCompetitions: PropTypes.func.isRequired,
  friluftsraad: PropTypes.array,
  competitions: PropTypes.array
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(translate("PageView")(PageView)));
