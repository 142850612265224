const fixDate = date => {
  if (date && typeof date === 'string') {
    if (date.indexOf('/Date') === 0) {
      date = date.replace('/Date(', '');
      date = date.replace(')/', '');
      return new Date(parseInt(date, 10) || date);
    } else {
      return new Date(date);
    }
  } else {
    return new Date(date);
  }
};

const validateDatePattern = datestring => {
  const validDatePattern = /^\d{4}-\d{1,2}-\d{1,2}$/; // yyyy.MM.dd
  if (!datestring || !validDatePattern.test(datestring)) {
    return false;
  }
  return true;
};

const isValidDate = datestring => {
  const timestamp = Date.parse(datestring);
  if (isNaN(timestamp)) {
    return false;
  }
  return true;
};

const isWithinYear = datestring => {
  const now = new Date();
  const then = new Date(datestring);
  if (now.getFullYear() === then.getFullYear()) {
    return true;
  }
  return false;
};

const isInFuture = datestring => {
  const now = new Date();
  const then = new Date(datestring);
  if (then > now) {
    return true;
  }
  return false;
};

export { fixDate, validateDatePattern, isValidDate, isWithinYear, isInFuture };
