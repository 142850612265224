import React from "react";
import PropTypes from "prop-types";
import NavBar from "../containers/NavBar";
import { withRouter } from "react-router-dom";
import Footer from "../components/Footer";

import "./CoreLayout.scss";
import { fixLinks } from "../utils/Path";

class CoreLayout extends React.Component {
  componentDidMount() {
    document.getElementsByClassName("react-openlayers--map")[0].classList.add("hide-map");
    fixLinks(this.props.history);
  }

  componentDidUpdate() {
    fixLinks(this.props.history);
  }

  render() {
    const {
      children,
      location: { pathname }
    } = this.props;

    return (
      <div className="core-layout--root">
        <NavBar />
        <div className={["core-layout--scroll-container", pathname.split("/")[1]].join(" ")}>
          <div className="core-layout--components">{children}</div>
          <Footer />
        </div>
      </div>
    );
  }
}

CoreLayout.propTypes = {
  children: PropTypes.element,
  location: PropTypes.object,
  history: PropTypes.object
};

export default withRouter(CoreLayout);
