import React from "react";
import { translate } from "react-translate";
import { withRouter, Link } from "react-router-dom";
import Icon from "./Icon";
import PropTypes from "prop-types";
import MyProfileForm from "./MyProfileForm";
import { fixDate } from "../utils/date";
import EditableSearchDropdown from "./EditableSearchDropdown";
import { logout, getSessionToken } from "@avinet/react-adaptive-auth";
import { getPageSections } from "../utils/Page";
import LoadingIndicator from "./LoadingIndicator";
import TranslatedServerError from "./TranslatedServerError";
import Title from "./Title";
import "./ProfilePage.scss";
import PopupBox from "./PopupBox";
import request from "superagent";
import { getConsents } from "../utils/privacy";
import config from "../config/TellTur";
import { validateProfile } from "../utils/profile";

class MyProfilePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mainMember: undefined,
      user: undefined,
      nick_name: "",
      firstname: "",
      surname: "",
      mobile: "",
      email: "",
      municipality: "",
      yearofbirth: -1,
      displayDeleteInfoBox: false,
      confirmDelete: false,
      validationErrors: {},
      sections: undefined,
      selectedFriluftsraadId: "",
      selectedCompanyId: -1,
      dropdownItems: undefined,
      displayPopup: false,
      consentEmail: false,
      consentLoading: false
    };

    this.handleFamilyMemberSubmit = this.handleFamilyMemberSubmit.bind(this);
    this.deleteAccount = this.deleteAccount.bind(this);
    this.setMainMember = this.setMainMember.bind(this);
    this.setUserFields = this.setUserFields.bind(this);
    this.handleUserSubmit = this.handleUserSubmit.bind(this);
    this.updateFriluftsraadForMembers = this.updateFriluftsraadForMembers.bind(this);
    this.validateValue = this.validateValue.bind(this);
    this.handleCompanySubmit = this.handleCompanySubmit.bind(this);
    this.toggleDisplayPopup = this.toggleDisplayPopup.bind(this);
  }

  componentDidMount() {
    if (this.props.members) {
      this.setMainMember(this.props.members);
    }
    if (this.props.user) {
      this.setUserFields(this.props.user);
    }
    this.checkForConsent();
  }

  componentWillReceiveProps(nextProps) {
    const { members, user, t } = this.props;
    if (!this.state.sections && nextProps.page && nextProps.page.content) {
      const sections = getPageSections(nextProps.page);
      this.setState({ sections });
    }
    if (nextProps.members && !members) {
      this.setMainMember(nextProps.members);
    }
    if (nextProps.user && !user) {
      this.setUserFields(nextProps.user);
    }
    if (
      (nextProps.serverError && !this.props.serverError) ||
      (nextProps.userError && !this.props.userError)
    ) {
      this.setState({ displayPopup: true });
    }

    if (members && user && this.state.mainMember) {
      const validProfile = validateProfile(user, this.state.mainMember);

      const validationErrors = {};

      validationErrors[Object.keys(validProfile.errors)] = t(Object.values(validProfile.errors));

      this.setState({ validationErrors });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { allFriluftsraad, friluftsraadMedlemskommuner, isLoading } = this.props;
    const { user, dropdownItems } = this.state;
    if (
      !isLoading &&
      allFriluftsraad.length &&
      friluftsraadMedlemskommuner.length &&
      !dropdownItems
    ) {
      this.getAssociatedFriluftsraad(parseInt(user.user_data.address_city));
    }
  }

  async checkForConsent() {
    const allConsent = await getConsents();

    allConsent.forEach(c => {
      if (c.category === config.consentEmail) {
        this.setState({ consentEmail: true });
      }
    });
  }

  onChangeConsent = (type, value) => {
    this.setState({ consentLoading: true });

    request
      .post(
        config.adaptiveUrl + "WebServices/generic/Privacy.asmx/" + (value ? "Accept" : "Revoke")
      )
      .set("gm_session_id", getSessionToken())
      .send({ category: type, version: 1 })
      .then(res => res.body)
      .then(res => {
        if (type === config.consentEmail) {
          this.setState({ consentEmail: value });
        }
        this.setState({ consentLoading: false });
      })
      .catch(err => {
        // eslint-disable-next-line
        console.warn(err);
        this.setState({ consentLoading: false });
      });
  };

  setMainMember(members) {
    const mainMember = members.find(m => m.is_main_familymember);
    if (mainMember) {
      this.setState({
        mainMember,
        nick_name: mainMember.nick_name,
        yearofbirth: mainMember.yearofbirth,
        selectedFriluftsraadId: mainMember.selected_friluftsraad_uuid,
        selectedCompanyId:
          mainMember.bedrift_id !== undefined &&
          mainMember.bedrift_id !== null &&
          mainMember.bedrift_id > 0
            ? mainMember.bedrift_id
            : -1
      });
      this.props.getCompanies(mainMember.selected_friluftsraad_uuid);
    }
  }

  setUserFields(user) {
    this.setState({
      user,
      firstname: user.user_data.first_name,
      surname: user.user_data.last_name,
      mobile: user.user_data.mobile,
      municipality: user.user_data.address_city,
      email: user.email
    });
  }

  deleteAccount(evt) {
    evt.preventDefault();
    this.props.deleteUser(this.state.user.uuid, () => {
      logout();
      this.props.history.push("/");
    });
  }

  validateValue(propertyName, newValue) {
    const { t } = this.props;
    var numberRegex = /^-?\d+\.?\d*$/;
    var validationErrors = {};

    if (propertyName === "yearofbirth") {
      if (
        !numberRegex.test(newValue) ||
        newValue < new Date().getFullYear() - 120 ||
        newValue > new Date().getFullYear()
      ) {
        validationErrors.yearofbirth = t("YearOfBirthNotValid");
      }
    }

    if (propertyName === "nick_name" && newValue.length < 4) {
      validationErrors.nick_name = t("UsernameTooShort");
    }

    if (propertyName === "firstname" && newValue.length < 2) {
      validationErrors.firstname = t("FirstnameNotValid");
    }

    if (propertyName === "surname" && newValue.length < 2) {
      validationErrors.surname = t("SurnameNotValid");
    }

    if (propertyName === "mobile" && (newValue.length < 7 || !numberRegex.test(newValue))) {
      validationErrors.mobile = t("MobileNotValid");
    }

    var emailRegEx =
      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    if (propertyName === "email" && !emailRegEx.test(newValue)) {
      validationErrors.email = t("EmailAddressNotValid");
    }

    if (propertyName === "municipality" && newValue.length < 2 && isNaN(newValue)) {
      validationErrors.municipality = t("MunicipalityNotValid");
    }

    this.setState({ validationErrors });
    if (Object.keys(validationErrors).length > 0) {
      return false;
    }
    return true;
  }

  handleFamilyMemberSubmit(propertyName, newValue) {
    const { mainMember } = this.state;
    if (propertyName === "yearofbirth" || propertyName === "bedrift_id") {
      newValue = parseInt(newValue);
    }
    if (this.state[propertyName] !== newValue) {
      if (this.validateValue(propertyName, newValue)) {
        this.setState({ [propertyName]: newValue }, () => {
          const member = mainMember;
          member.nick_name = this.state.nick_name;
          member.yearofbirth = this.state.yearofbirth;
          member.bedrift_id =
            this.state.selectedCompanyId === -1 ? null : this.state.selectedCompanyId;
          member.create_date = fixDate(member.create_date);
          this.props.addMember(member);
        });
      }
    }
  }

  handleUserSubmit(propertyName, newValue) {
    if (this.state[propertyName] !== newValue) {
      if (this.validateValue(propertyName, newValue)) {
        this.setState({ [propertyName]: newValue }, () => {
          const user = this.state.user;
          user.user_data.first_name = this.state.firstname;
          user.user_data.last_name = this.state.surname;
          user.user_data.mobile = this.state.mobile;
          user.user_data.address_city = this.state.municipality;
          user.email = this.state.email.toLowerCase();

          this.props.updateUser(user);
          this.setState({ dropdownItems: undefined });
        });
      }
    }
  }

  getAssociatedFriluftsraad(newValue) {
    const { allFriluftsraad, friluftsraadMedlemskommuner } = this.props;
    let associatedFriluftsraadArr = [];

    if (!newValue) {
      this.setState({ dropdownItems: allFriluftsraad });
      return;
    }

    const associatedFriluftsraad = friluftsraadMedlemskommuner.filter(
      fr => fr.kommune === newValue
    );

    if (!associatedFriluftsraad || !associatedFriluftsraad.length) {
      this.setState({ dropdownItems: allFriluftsraad });
      return;
    }

    associatedFriluftsraadArr = allFriluftsraad.filter(fr =>
      associatedFriluftsraad.some(asf => asf.fr_id === fr.rolle_id)
    );
    this.setState({
      dropdownItems: [
        {
          label: "Friluftsråd for din bostedskommune",
          options: associatedFriluftsraadArr
        },
        {
          label: "Andre TellTur-friluftsråd",
          options: allFriluftsraad.filter(fr =>
            associatedFriluftsraad.some(asf => asf.fr_id !== fr.rolle_id)
          )
        }
      ]
    });
  }

  updateFriluftsraadForMembers(uuid) {
    if (uuid === "") {
      this.setState({
        selectedFriluftsraadErrorText: this.props.t("SelectedFriluftsraadIsInvalid")
      });
      return;
    }
    this.props.members.forEach(m => {
      m.selected_friluftsraad_uuid = uuid;
      m.bedrift_id = null;
      m.create_date = fixDate(m.create_date);
      this.props.addMember(m);
    });
    this.setState({ changeFriluftsraad: false, selectedFriluftsraadId: uuid }, () => {
      this.props.getCompanies(uuid);
    });
  }

  handleCompanySubmit(id) {
    this.handleFamilyMemberSubmit("selectedCompanyId", id);
  }

  get getGroupedMunicipalities() {
    const { municipalities } = this.props;
    const fylker = config.fylkesnummer;

    const municipalitiesGroupedByCounties = fylker
      .map(fylke => {
        return {
          label: fylke.navn,
          options: municipalities
            .filter(municipality => {
              if (municipality.komm < 999) {
                return fylke.nummer === "03"; // this is bcs kommunenummer are stored in psql as int and for Oslo is stored as 301 instead 0301 becouse of leading 0
              }
              return String(municipality.komm).slice(0, 2) === fylke.nummer;
            })
            .sort((a, b) => (a.navn > b.navn ? 1 : b.navn > a.navn ? -1 : 0))
        };
      })
      .sort((a, b) => (a.navn > b.navn ? 1 : b.navn > a.navn ? -1 : 0));

    return municipalitiesGroupedByCounties;
  }

  toggleDisplayPopup = () => {
    this.setState(prevState => ({
      displayPopup: !prevState.displayPopup
    }));
  };

  render() {
    const {
      t,
      page,
      serverError,
      userError,
      isLoading,
      loading,
      municipalities,
      allFriluftsraad,
      ...restOfProps
    } = this.props;
    const {
      sections,
      displayDeleteInfoBox,
      confirmDelete,
      error,
      changeFriluftsraad,
      selectedFriluftsraadId,
      selectedCompanyId,
      dropdownItems,
      consentLoading,
      consentEmail,
      ...restOfState
    } = this.state;

    const groupedMunicipalities = this.getGroupedMunicipalities;

    const dummyCompanyArray = this.props.companies
      ? [
          {
            id: -1,
            navn: t("Unjoin"),
            className: "unjoin",
            unjoin: true
          },
          ...this.props.companies
        ]
      : [];
    const dummyCompanyArrayStyles = {
      option: (styles, { data }) => {
        return {
          ...styles,
          borderBottom: data.unjoin ? "1px dashed hsl(0,0%,80%)" : null
        };
      }
    };

    return (
      <div className="tell-tur-page">
        {(isLoading ||
          consentLoading ||
          (loading && municipalities.length === 0) ||
          !dropdownItems) && (
          <div className="loader">
            <LoadingIndicator />
          </div>
        )}
        {this.state.displayPopup && (
          <PopupBox onClose={this.toggleDisplayPopup} closeBtnText={"Ok"}>
            <TranslatedServerError
              className="telltur-profile-form--popup"
              error={serverError || userError}
            />
          </PopupBox>
        )}
        {dropdownItems && (
          <div>
            <Title title={page.title} />
            <h1>{page.title}</h1>
            {sections && (
              <div
                className="tell-tur-page--intro-text"
                dangerouslySetInnerHTML={{
                  __html: sections["YourAccountSection"]
                }}
              />
            )}
            <MyProfileForm
              {...restOfProps}
              {...restOfState}
              municipalities={groupedMunicipalities}
              handleFamilyMemberSubmit={this.handleFamilyMemberSubmit}
              handleUserSubmit={this.handleUserSubmit}
              displayPopup={this.toggleDisplayPopup}
            />
            <EditableSearchDropdown
              placeholder={t("ConnectedToFriluftsraad")}
              controlClassName="tell-tur-form--control"
              labelText={t("ConnectedToFriluftsraad")}
              inputType="Text"
              onSubmit={(key, newValue) => {
                this.updateFriluftsraadForMembers(newValue);
              }}
              controlValue={selectedFriluftsraadId}
              dropdownItems={dropdownItems}
              errorClassName="error friluftsraad-error"
              labelPropName="navn"
              valuePropName="uuid"
              controlKey="uuid"
            />
            <EditableSearchDropdown
              placeholder={t("ConnectedToCompany")}
              controlClassName="tell-tur-form--control"
              labelText={t("ConnectedToCompany")}
              inputType="Text"
              defaultValue={dummyCompanyArray[0]}
              onSubmit={(key, newValue) => {
                this.handleCompanySubmit(newValue);
              }}
              styles={dummyCompanyArrayStyles}
              controlValue={selectedCompanyId !== -1 ? selectedCompanyId : null}
              dropdownItems={dummyCompanyArray}
              errorClassName="error friluftsraad-error"
              labelPropName="navn"
              valuePropName="id"
              controlKey="id"
            />

            <label className="checkbox-wrapper">
              <input
                type="checkbox"
                checked={consentEmail || false}
                onChange={e => this.onChangeConsent(config.consentEmail, e.target.checked)}
              />
              <span className="checkmark" />
              {t("Agree")}
            </label>

            <div className="vertical-spacing">
              <div className="text-icon-left">
                <Icon name="unlock" />
              </div>
              <span className="tell-tur-link">
                <Link to="/endrepassord">{t("ChangePassword") + "?"}</Link>
              </span>{" "}
              |&nbsp;
              <div className="text-icon-left">
                <Icon name="usersGroup" />
              </div>
              <span className="tell-tur-link">
                <Link to="/nyttmedlem">{t("EditFamilyMembers")}</Link>
              </span>{" "}
              |&nbsp;
              <div className="text-icon-left">
                <Icon name="trash" />
              </div>
              <span className="tell-tur-link">
                <button
                  className="btn-link"
                  onClick={() => this.setState({ displayDeleteInfoBox: true })}
                >
                  {t("DeleteAccount")}
                </button>
              </span>
            </div>
            {sections && displayDeleteInfoBox && (
              <div className="info-box warning">
                <div
                  dangerouslySetInnerHTML={{
                    __html: sections["DeleteAccountSection"]
                  }}
                />
                <div className="info-box--button-row">
                  <div
                    className="checkbox"
                    onClick={() => this.setState({ confirmDelete: !confirmDelete })}
                  >
                    {confirmDelete && <Icon name="checkmark" />}
                  </div>
                  <div className="text">{t("ConfirmDelete")}</div>
                  <div className="buttons">
                    <span>
                      <button
                        onClick={this.deleteAccount}
                        disabled={!confirmDelete}
                        className="btn btn-primary"
                      >
                        {t("DeleteAccount")}
                      </button>
                    </span>
                  </div>
                </div>
              </div>
            )}
            <div className="tell-tur-page--footer" />
          </div>
        )}
      </div>
    );
  }
}

MyProfilePage.propTypes = {
  t: PropTypes.func.isRequired,
  page: PropTypes.object,
  isLoading: PropTypes.bool,
  user: PropTypes.object,
  addMember: PropTypes.func.isRequired,
  members: PropTypes.array,
  serverError: PropTypes.object,
  userError: PropTypes.object,
  updateUser: PropTypes.func.isRequired,
  deleteUser: PropTypes.func.isRequired,
  history: PropTypes.object,
  allFriluftsraad: PropTypes.array.isRequired,
  friluftsraadMedlemskommuner: PropTypes.array.isRequired,
  municipalities: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  companies: PropTypes.array,
  getCompanies: PropTypes.func.isRequired
};

export default withRouter(translate("MyProfilePage")(MyProfilePage));
