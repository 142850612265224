import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const mapStateToProps = state => ({
  sessionToken: state.auth.sessionToken
});

const PrivateRoute = ({ component: Component, loginPath, sessionToken, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      return sessionToken ? <Component {...props} /> : <Redirect to={loginPath} />;
    }}
  />
);

PrivateRoute.propTypes = {
  sessionToken: PropTypes.any,
  loginPath: PropTypes.string.isRequired,
  component: PropTypes.any
};

export default connect(mapStateToProps)(PrivateRoute);
