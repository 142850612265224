var getPageSections = page => {
  var sections = null;
  if (page && page.content) {
    var sectionArray = [];
    var tmp1 = page.content.split('[');

    tmp1.forEach(s => {
      sectionArray = sectionArray.concat(s.split(']'));
    });

    sectionArray = sectionArray.filter(s => s.length !== 0);
    if (sectionArray.length < 2) {
      return null;
    }
    sections = {};
    var i = 0;
    for (i = 0; i < sectionArray.length - 1;) {
      var propertyName = sectionArray[i].trim();
      var value = sectionArray[i + 1];
      sections[propertyName] = value;
      i = i + 2;
    }
  }
  return sections;
};

export { getPageSections };
