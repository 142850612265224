// import { readFiltered } from './DigiTheme';
import { post } from "../requests";
import { fixKeyValueArraysToJsObject } from "../utils/array";
import config from "../config/TellTur";
// import { getSessionToken } from '@avinet/react-adaptive-auth';

// const friluftsraadColumns = ['id', 'navn', 'beskrivelse'];
// ------------------------------------
// Constants
// ------------------------------------
export const READING_ALL_FRILUFTSRAAD = "READING_ALL_FRILUFTSRAAD";
export const ALL_FRILUFTSRAAD_READ = "ALL_FRILUFTSRAAD_READ";

// ------------------------------------
// Actions
// ------------------------------------

const readAllFriluftsraad = () => ({ type: READING_ALL_FRILUFTSRAAD });

const allFriluftsraadRead = (error, allFriluftsraad) => ({
  type: ALL_FRILUFTSRAAD_READ,
  payload: {
    error,
    allFriluftsraad
  }
});

// ------------------------------------
// Action creators
// ------------------------------------
export function getAllFriluftsraad() {
  return dispatch => {
    dispatch(readAllFriluftsraad());

    // const filter = {
    //   filterColumns: [
    //     {
    //       name: 'role_type',
    //       value: [2],
    //       netType: 'int[]',
    //       comparisonOperator: 'ANY',
    //       logicalOperator: 'AND'
    //     }
    //     // {
    //     //   name: 'data',
    //     //   value: 'telltur-friluftsraad',
    //     //   comparisonOperator: '=',
    //     //   logicalOperator: 'AND'
    //     // }
    //   ],
    //   sortColumns: [],
    //   secondFilter: [],
    //   defaultFilter: [],
    //   distinctColumns: []
    // };

    const url = config.adaptiveUrl + "WebServices/telltur/Friluftsraad.asmx/GetAllFriluftsraad";
    // const url = 'http://localhost/a_a3/WebServices/telltur/Friluftsraad.asmx/GetAllFriluftsraad';
    const headers = {
      Accept: "application/json",
      gm_lang_code: config.gm_lang_code,
      gm_session_id: "b6a14332e5723963dfa0158b1b070d2eca5016c3" // getSessionToken()
    };

    const params = {};
    post(url, headers, params, (err, response) => {
      if (err || !response.ok) {
        dispatch(allFriluftsraadRead(err || { message: "Received an error from server" }, null));
        return;
      }
      response = response.body.d;

      if (response.exception || !response.success) {
        dispatch(
          allFriluftsraadRead(
            response.exception || {
              message: "Received an error from server"
            },
            null
          )
        );
        return;
      }
      var records = fixKeyValueArraysToJsObject(response.records);
      records = records.filter(
        r =>
          r.name !== "Telltur test" &&
          r.name !== "Sandkasse" &&
          r.name !== "TellTur Barn!" &&
          r.name !== "TellTur Bedrift!"
      );
      dispatch(allFriluftsraadRead(null, records));
    });
    // readFiltered(
    //   config.friluftsraadDigiThemeUuid,
    //   friluftsraadColumns,
    //   filter,
    //   (err, response) => {
    //     if (err || !response.ok) {
    //       dispatch(allFriluftsraadRead(err || { message: 'Received an error from server' }, null));
    //       return;
    //     }
    //     response = response.body.d;

    //     if (response.exception || !response.success) {
    //       dispatch(
    //         allFriluftsraadRead(
    //           response.exception || {
    //             message: 'Received an error from server'
    //           },
    //           null
    //         )
    //       );
    //       return;
    //     }

    //     dispatch(allFriluftsraadRead(null, response.records));
    //   }
    // );
  };
}

// export function getAllFriluftsraad () {
//   return dispatch => {
//     dispatch(readAllFriluftsraad());

//     const filter = {
//       filterColumns: [],
//       sortColumns: [],
//       secondFilter: [],
//       defaultFilter: [],
//       distinctColumns: []
//     };

//     readFiltered(
//       config.friluftsraadDigiThemeUuid,
//       friluftsraadColumns,
//       filter,
//       (err, response) => {
//         if (err || !response.ok) {
//           dispatch(allFriluftsraadRead(err || { message: 'Received an error from server' }, null));
//           return;
//         }
//         response = response.body.d;

//         if (response.exception || !response.success) {
//           dispatch(
//             allFriluftsraadRead(
//               response.exception || {
//                 message: 'Received an error from server'
//               },
//               null
//             )
//           );
//           return;
//         }

//         dispatch(allFriluftsraadRead(null, response.records));
//       }
//     );
//   };
// }

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  loading: false,
  error: undefined,
  allFriluftsraad: undefined
};

export function friluftsraadReducer(state = initialState, action) {
  switch (action.type) {
    case READING_ALL_FRILUFTSRAAD:
      return Object.assign({}, state, {
        loading: true,
        friluftsraad: undefined,
        error: undefined
      });
    case ALL_FRILUFTSRAAD_READ:
      return Object.assign({}, state, {
        loading: false,
        error: action.payload.error,
        allFriluftsraad: action.payload.allFriluftsraad
      });
    default:
      return state;
  }
}
