import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useTranslate } from "react-translate";
import { ensureArticleModuleConfig } from "@avinet/react-article-module";
import Style from "ol/style/Style";
import Fill from "ol/style/Fill";
import Stroke from "ol/style/Stroke";
import Circle from "ol/style/Circle";
import LoadingIndicator from "../components/LoadingIndicator";
import Icon from "../components/Icon";
import useSearch from "../hooks/useSearch";
import { provideMapState, VectorSource } from "@avinet/react-openlayers";
import useActiveSources from "../hooks/useActiveSources";
import "./TellturSearch.scss";

TellturSearch.propTypes = {
  sources: PropTypes.array.isRequired,
  onResultSelected: PropTypes.func.isRequired,
  isOpen: PropTypes.bool
};

const styleFunction = feature => {
  return new Style({
    image: new Circle({
      stroke: new Stroke({ color: feature.get("hover") ? "#1173af" : "#ffffff", width: 3 }),
      fill: new Fill({ color: "rgb(" + feature.get("color") + ")" }),
      radius: feature.get("hover") ? 10 : 7
    }),
    zIndex: feature.get("hover") ? 2 : 0
  });
};

function SearchResultComponent({ result, onResultSelected }) {
  return (
    <li
      key={result.id}
      className="clearfix"
      onClick={() => onResultSelected(result, result.source)}
      onMouseOver={() => result.olFeature && result.olFeature.set("hover", true)}
      onMouseOut={() => result.olFeature && result.olFeature.set("hover", false)}
    >
      <h3>
        <span
          style={{
            display: "inline-block",
            width: 10,
            height: 10,
            borderRadius: 10,
            background: "rgb(" + result.source.config.fillColor + ")",
            marginRight: 5
          }}
          title={result.source.text}
        />
        {result.properties.title}
      </h3>
      {result.properties.content && (
        <p
          className="searchSource--content"
          dangerouslySetInnerHTML={{ __html: result.properties.content.substring(0, 150) }}
        />
      )}
    </li>
  );
}

function TellturSearch({ onResultSelected, sources, isOpen, mapExtent, mapCenter }) {
  const t = useTranslate("TellturSearch");
  const [query, setQuery] = useState("");
  const [debouncedQuery, setDebouncedQuery] = useState("");
  const [visible, setVisible] = useState(false);
  const searchField = useRef();

  useEffect(() => {
    if (!query) {
      setDebouncedQuery("");
      return;
    }
    const tId = setTimeout(() => setDebouncedQuery(query), 500);
    return () => clearTimeout(tId);
  }, [query]);

  useEffect(() => {
    if (searchField.current && isOpen) searchField.current.focus();
  }, [isOpen]);

  const activeSourceIds = useActiveSources();
  const activeSources = useMemo(() => {
    if ((activeSourceIds?.length ?? 0) === 0) return sources;
    return sources.filter(s => activeSourceIds.indexOf(s.dataIndex) !== -1);
  }, [activeSourceIds, sources]);

  const { loading, results } = useSearch({
    query: debouncedQuery,
    sources: activeSources,
    mapExtent,
    mapCenter
  });

  const features = useMemo(() => {
    if (!results) return [];
    return Object.values(results)
      .map(result => result.olFeature)
      .filter(f => !!f);
  }, [results]);

  const handleResultSelected = useCallback(
    (result, source) => {
      onResultSelected(result, source);
      setQuery("");
      setVisible(false);
    },
    [onResultSelected]
  );

  return (
    <div className="telltur-search--container">
      <div className="telltur-search--inputWrap">
        <input
          type="text"
          placeholder={
            activeSourceIds.length === 0 || activeSourceIds.length === sources.length
              ? t("tellturSearchPlaceholder")
              : t("tellturSearchPlaceholderSelected", {
                  sources: activeSources
                    .map(s => s.text.replace(/Telltur - /, "").toLowerCase())
                    .join(", ")
                })
          }
          required
          ref={searchField}
          value={query}
          onChange={e => setQuery(e.target.value)}
          onFocus={() => setVisible(true)}
        />
        <button
          className="telltur-search--clear-btn"
          onClick={() => {
            setQuery("");
            setVisible(false);
          }}
        >
          <Icon name="cross" />
        </button>
      </div>
      {visible && (
        <div className="telltur-search--results-wrapper">
          {loading && (
            <div className="telltur-search--loadingElem">
              <LoadingIndicator />
            </div>
          )}
          <div className="telltur-search--searchSources">
            <div className="searchSource--container">
              <VectorSource
                layerName="search-results"
                features={features}
                style={styleFunction}
                zIndex={100}
              />
              {!!results && (
                <ul>
                  {results.slice(0, 50).map(result => (
                    <SearchResultComponent
                      key={result.id}
                      result={result}
                      onResultSelected={handleResultSelected}
                    />
                  ))}
                </ul>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default provideMapState(ensureArticleModuleConfig(TellturSearch));
