import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import TripList from "../components/MyTrips/TripList";
import Title from "../components/Title";
import MemberSelect from "../components/MyTrips/MemberSelect";
import TranslatedServerError from "../components/TranslatedServerError";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { translate } from "react-translate";
import { readTrips, deleteTrip } from "../redux/trips";
import { readMembers } from "../redux/familymembers";

import "./MyTripsContainer.scss";

const mapDispatchToProps = dispatch => {
  return {
    readTrips: bindActionCreators(readTrips, dispatch),
    deleteTrip: bindActionCreators(deleteTrip, dispatch),
    readMembers: bindActionCreators(readMembers, dispatch)
  };
};

const mapStateToProps = state => ({
  trips: state.trips.trips,
  tripDeleted: state.trips.tripDeleted,
  tripsError: state.trips.error,
  tripsLoading: state.trips.loading,
  user: state.user.user,
  members: state.familyMembers.members,
  memberError: state.familyMembers.error
});

class MyTripsContainer extends React.Component {
  constructor(props) {
    super(props);

    const thisYear = new Date().getFullYear();

    this.state = {
      thisYear,
      selectedYear: thisYear,
      selectedMemberId: -1,
      error: null
    };

    this.handleDeleteTrip = this.handleDeleteTrip.bind(this);
    this.changeCurrentMember = this.changeCurrentMember.bind(this);
    this.onYearSelected = this.onYearSelected.bind(this);
  }

  componentWillMount() {
    if (this.props.user) {
      this.props.readMembers(this.props.user.uuid);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.user && this.props.user) {
      this.props.readMembers(this.props.user.uuid);
    }
    if (!prevProps.tripDeleted && this.props.tripDeleted) {
      this.props.readTrips(
        this.state.selectedMemberId,
        this.state.selectedYear
      );
    }

    if (
      this.props.members &&
      this.props.members.length &&
      this.state.selectedMemberId === -1
    ) {
      this.setMainMember();
    }

    if (
      this.state.selectedMemberId !== -1 &&
      (prevState.selectedMemberId !== this.state.selectedMemberId ||
        prevState.selectedYear !== this.state.selectedYear)
    ) {
      this.props.readTrips(
        this.state.selectedMemberId,
        this.state.selectedYear
      );
    }
  }

  setMainMember() {
    const mainMember = this.props.members.find(m => m.is_main_familymember);
    if (mainMember) {
      this.setState({
        selectedMemberId: mainMember.id
      });
    }
  }

  changeCurrentMember(id) {
    if (!Number.isNaN(id)) {
      this.setState({
        selectedMemberId: id
      });
    }
  }

  generateOptionsFromYear(fromYear) {
    const { thisYear } = this.state;
    let options = [];

    for (let i = thisYear; i >= fromYear; i--) {
      options.push({ key: i, value: i });
    }

    return options;
  }

  onYearSelected(year) {
    const selectedYear = parseInt(year);
    this.setState({ selectedYear });
  }

  handleDeleteTrip(id) {
    this.props.deleteTrip(id, this.state.selectedMemberId);
  }

  render() {
    const {
      page,
      trips,
      members,
      memberError,
      tripsLoading,
      t,
      tripsError
    } = this.props;
    const { selectedMemberId, thisYear } = this.state;
    const options = this.generateOptionsFromYear(2018);

    return (
      <div className="tell-tur-page">
        <Title title={page.title} />
        <h1>{page.title}</h1>
        {tripsError && <TranslatedServerError error={tripsError} />}
        <MemberSelect
          infoText={page.content}
          isSearchable={false}
          members={members}
          memberError={memberError}
          changeCurrentMember={this.changeCurrentMember}
          selectedMemberId={selectedMemberId}
        >
          <span className="tell-tur-form--control">
            <Select
              classNamePrefix="editable-search-dropdown"
              defaultValue={options.find(y => y.value === thisYear)}
              isSearchable={false}
              getOptionLabel={y => y.value}
              options={options}
              onChange={(di, action) => {
                if (action.action === "select-option") {
                  this.onYearSelected(di.value);
                }
              }}
            />
          </span>
        </MemberSelect>
        <TripList
          trips={trips}
          noTripsText={t("NoTrips")}
          handleDeleteTrip={this.handleDeleteTrip}
          loading={tripsLoading}
        />
      </div>
    );
  }
}

MyTripsContainer.propTypes = {
  page: PropTypes.object,
  trips: PropTypes.array,
  readTrips: PropTypes.func,
  deleteTrip: PropTypes.func,
  tripDeleted: PropTypes.number,
  tripsError: PropTypes.object,
  tripsLoading: PropTypes.bool,
  user: PropTypes.object,
  readMembers: PropTypes.func,
  memberError: PropTypes.object,
  members: PropTypes.array,
  t: PropTypes.func.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(translate("MyTripsContainer")(MyTripsContainer));
