import React from "react";
import PropTypes from "prop-types";
import { MapPadding } from "@avinet/react-openlayers";
import { provideViewSize } from "../hocs/provideViewSize";
import NavBar from "../containers/NavBar";
import "./MapLayout.scss";

class MapLayout extends React.Component {
  componentDidMount() {
    document.getElementsByClassName("react-openlayers--map")[0].classList.remove("hide-map");
  }

  calcMapPadding() {
    const { viewHeight, viewWidth } = this.props;
    if (viewWidth < 769) {
      return [80, 0, viewHeight / 4, 0];
    }
    return [80, 420, 0, 0];
  }

  render() {
    const { children } = this.props;
    return (
      <div className="map-layout--root">
        <MapPadding padding={this.calcMapPadding()} />
        <NavBar />
        <div className="map-layout--components">{children}</div>
      </div>
    );
  }
}

MapLayout.propTypes = {
  children: PropTypes.element,
  viewWidth: PropTypes.number.isRequired,
  viewHeight: PropTypes.number.isRequired
};

export default provideViewSize(MapLayout);
