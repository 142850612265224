import React from 'react';
import PropTypes from 'prop-types';
import './Switch.scss';

const Switch = ({ labelOn, labelOff, valueOn, valueOff, value, onChange, disable }) => (
  <div className={['switch', disable ? 'disable' : ''].join(' ')}>
    <button
      className={['switch-btn', value === valueOff ? 'active' : ''].join(' ')}
      onClick={() => onChange(valueOff)}
    >
      {labelOff}
    </button>
    <button
      className={['switch-btn', value === valueOn ? 'active' : ''].join(' ')}
      onClick={() => onChange(valueOn)}
    >
      {labelOn}
    </button>
  </div>
);

Switch.propTypes = {
  /** Use callback to toggle state in parent component */
  onChange: PropTypes.func.isRequired,
  /** True value */
  valueOn: PropTypes.any,
  /** True label */
  labelOn: PropTypes.string.isRequired,
  /** False value */
  valueOff: PropTypes.any,
  /** False label */
  labelOff: PropTypes.string.isRequired,
  /** Value of switch */
  value: PropTypes.any.isRequired,
  /** Disable switch */
  disable: PropTypes.bool
};

Switch.defaultProps = {
  valueOn: true,
  valueOff: false
};
export default Switch;
