import request from "superagent";
import { getSessionToken, authReducerActions } from "@avinet/react-adaptive-auth";
import { fixDate } from "../utils/date";
import config from "../config/TellTur";

// ------------------------------------
// Constants
// ------------------------------------
export const READING_TRIPS = "READING_TRIPS";
export const TRIPS_READ = "TRIPS_READ";

export const ADDING_TRIP = "ADDING_TRIP";
export const TRIP_ADDED = "TRIP_ADDED";
export const RESET_ADDED_TRIP = "RESET_ADDED_TRIP";

export const DELETING_TRIP = "DELETING_TRIP";
export const TRIP_DELETED = "TRIP_DELETED";

// ------------------------------------
// Actions
// ------------------------------------
const addingTrip = () => ({
  type: ADDING_TRIP
});

const tripAdded = (errorMessage, tripAdded) => ({
  type: TRIP_ADDED,
  payload: {
    error: errorMessage,
    tripAdded
  }
});

const resetAddedTrip = () => ({
  type: RESET_ADDED_TRIP
});

const readingTripsAction = () => ({
  type: READING_TRIPS
});

const tripsReadAction = (errorMessage, trips) => ({
  type: TRIPS_READ,
  payload: {
    error: errorMessage,
    trips: trips
  }
});

const tripDeleted = (error, tripDeleted) => ({
  type: TRIP_DELETED,
  payload: {
    error,
    tripDeleted
  }
});

const deletingTrip = () => ({
  type: DELETING_TRIP
});

// ------------------------------------
// Action creators
// ------------------------------------

export function clearAddedTrip() {
  return dispatch => {
    dispatch(resetAddedTrip());
  };
}

export function deleteTrip(tripId, familyMemberId) {
  return dispatch => {
    dispatch(deletingTrip());

    request
      .post(config.adaptiveUrl + "WebServices/telltur/Trips.asmx/Delete")
      // .post('http://localhost/a_a3/WebServices/telltur/Trips.asmx/Delete')
      .send({
        id: tripId,
        user_id: familyMemberId
      })
      .set("Accept", "application/json")
      .set("gm_session_id", getSessionToken())
      .set("gm_lang_code", config.gm_lang_code)
      .end((err, response) => {
        if (err || !response.ok) {
          dispatch(tripDeleted("Error returned from service"));
          return;
        }
        response = response.body.d;

        if (response.sessionExpired) {
          dispatch(authReducerActions.sessionExpired());
        }

        if (response.exception || !response.success) {
          dispatch(tripDeleted(response.exception || { message: "Error returned from service" }));
          return;
        }
        dispatch(tripDeleted(null, tripId));
      });
  };
}

export function addTrip(trip) {
  return dispatch => {
    dispatch(addingTrip());

    request
      .post(config.adaptiveUrl + "WebServices/telltur/Trips.asmx/Create")
      // .post('http://localhost/a_a3/WebServices/telltur/Trips.asmx/Create')
      .send({
        tripcode: trip.tripcode,
        tripdate: trip.tripdate,
        user_id: trip.userid,
        description: trip.description
      })
      .set("Accept", "application/json")
      .set("gm_session_id", getSessionToken())
      .set("gm_lang_code", config.gm_lang_code)
      .end((err, response) => {
        if (err || !response.ok) {
          dispatch(tripAdded("Error returned from service"));
          return;
        }
        response = response.body.d;

        if (response.sessionExpired) {
          dispatch(authReducerActions.sessionExpired());
        }

        if (response.exception || !response.success) {
          dispatch(tripAdded(response.exception || { message: "Error returned from service" }));
          return;
        }
        dispatch(tripAdded(null, trip.tripcode));
      });
  };
}

export function readTrips(userId, year, sortDescending = true, start = 0, limit = 0) {
  return dispatch => {
    dispatch(readingTripsAction());
    const filter = {
      filterColumns: [
        {
          name: "user_id",
          value: userId,
          comparisonOperator: "=",
          netType: "Text",
          logicalOperator: "AND"
        },
        {
          name: "date",
          value: new Date(Date.UTC(year, 0, 1)),
          comparisonOperator: ">=",
          logicalOperator: "AND"
        },
        {
          name: "date",
          value: new Date(Date.UTC(year + 1, 0, 1)),
          comparisonOperator: "<",
          logicalOperator: "AND"
        }
      ],
      sortColumns: [
        {
          name: "date",
          sortDesc: sortDescending
        }
      ],
      secondFilter: [],
      defaultFilter: [],
      distinctColumns: []
    };
    request
      .post(config.adaptiveUrl + "WebServices/telltur/Trips.asmx/Read")
      // .post('http://localhost/a_a3/WebServices/telltur/Trips.asmx/Read')
      .send({
        start,
        limit,
        filter
      })
      .set("Accept", "application/json")
      .set("gm_session_id", getSessionToken())
      .set("gm_lang_code", config.gm_lang_code)
      .end((err, response) => {
        if (err || !response.ok) {
          dispatch(tripsReadAction("Error returned from service"));
          return;
        }
        response = response.body.d;

        if (response.sessionExpired) {
          dispatch(authReducerActions.sessionExpired());
        }

        if (response.exception || !response.success || !response.data) {
          dispatch(
            tripsReadAction(response.exception || { message: "Error returned from service" })
          );
          return;
        }
        var trips = [];
        var records = response.data.find(item => item.key === "records");
        if (records.value) {
          trips = records.value.map(r => {
            r.date = fixDate(r.date);
            return r;
          });
        }

        dispatch(tripsReadAction(null, trips));
      });
  };
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  loading: false,
  error: null,
  tripAdded: null,
  tripDeleted: null,
  trips: []
};

export function tripsReducer(state = initialState, action) {
  switch (action.type) {
    case ADDING_TRIP:
      return Object.assign({}, state, {
        loading: true,
        error: null
      });
    case TRIP_ADDED:
      return Object.assign({}, state, {
        loading: false,
        error: action.payload.error ? action.payload.error : null,
        tripAdded: action.payload.tripAdded
      });
    case RESET_ADDED_TRIP:
      return Object.assign({}, state, {
        loading: false,
        error: null,
        tripAdded: null
      });
    case READING_TRIPS:
      return Object.assign({}, state, {
        loading: true,
        error: null,
        trips: [],
        tripDeleted: null
      });
    case TRIPS_READ:
      return Object.assign({}, state, {
        loading: false,
        trips: action.payload.trips,
        error: action.payload.error ? action.payload.error : null
      });
    case DELETING_TRIP:
      return Object.assign({}, state, {
        loading: true,
        error: null
      });
    case TRIP_DELETED:
      return Object.assign({}, state, {
        error: action.payload.error ? action.payload.error : null,
        loading: false,
        tripDeleted: action.payload.tripDeleted
      });
    default:
      return state;
  }
}
