import React from "react";
import { olUtils, VectorSource } from "@avinet/react-openlayers";
import { actions as digiThemeActions } from "../../utils/digiTheme";
import scopedBindActionCreators from "../../utils/scopedReducer";
import { connect } from "react-redux";
import { Style, Icon } from "ol/style";
import config from "../../config/TellTur";
import Parking from "../Parking";

import parkingIconMap from "../../static/parking_map.svg";
import parkingChargIconMap from "../../static/p_charger_map.svg";

const mapDispatchToProps = dispatch => {
  return {
    readParkings: scopedBindActionCreators(digiThemeActions.readFiltered, dispatch, "parkings")
  };
};

const mapStateToProps = state => ({
  parkings: state.parkings.records,
  loadingParkings: state.parkings.loading
});

class ParkingLayer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      features: [],
      showParkingDetails: false
    };
    this.handleClose = this.handleClose.bind(this);
  }
  componentDidMount() {
    this.readParkings();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.parkings !== this.props.parkings) {
      this.setFeatures(this.props.parkings);
    }
  }

  setFeatures(parkings) {
    const features =
      !parkings && !parkings.length
        ? []
        : parkings.map(p => {
            const f = olUtils.createFeatureFromWkt(p.geom_wkt, {
              id: p.id,
              type: p.type_id,
              media: p.bilde_multimedia,
              title: p.navn,
              description: p.beskrivelse,
              accessibility: p.tilrettelagt
            });

            if (!f.onClick) {
              f.onClick = () => {
                this.setState({ selectedParking: p, showParkingDetails: true });
              };
            }

            return f;
          });
    this.setState({ features });
  }

  featureStyle = f => {
    return new Style({
      image: new Icon({
        src: f.get("type") === 55 ? parkingIconMap : parkingChargIconMap,
        scale: 0.6
      })
    });
  };

  readParkings() {
    this.props.readParkings(config.parkingsDt.columns, null, config.mapProjCode, 0, 0, null);
  }

  handleClose() {
    this.setState({ showParkingDetails: false });
  }

  render() {
    const { features, selectedParking, showParkingDetails } = this.state;

    return (
      <>
        <VectorSource
          layerName="parking_select"
          features={features}
          styleFn={() => this.featureStyle}
          minZoom={7}
        />
        {selectedParking && showParkingDetails && (
          <Parking
            selectedParking={selectedParking}
            title={selectedParking.navn}
            description={selectedParking.beskrivelse}
            type={selectedParking.type_id}
            onClose={this.handleClose}
          />
        )}
      </>
    );
  }
}

ParkingLayer.propTypes = {};

export default connect(mapStateToProps, mapDispatchToProps)(ParkingLayer);
