import React from "react";
import { MapContext } from "@avinet/react-openlayers";
import { useContext, useEffect } from "react";
import MVT from "ol/format/MVT";
import Fill from "ol/style/Fill";
import Style from "ol/style/Style";
import VectorTile from "ol/layer/VectorTile";
import VectorTileSource from "ol/source/VectorTile";

const defaultVectorTileStyle = new Style({
  fill: new Fill({ color: "#d2e67c33" })
});

/**
 * Add a vector tile layer to the map.
 */
export default function VectorTileLayer({ maxZoom, minZoom, url }) {
  const { map } = useContext(MapContext);

  useEffect(() => {
    const projection = map.getView().getProjection();
    const source = new VectorTileSource({
      format: new MVT(),
      url,
      maxZoom: 18,
      projection
    });

    const layer = new VectorTile({
      source,
      minZoom,
      maxZoom,
      style: defaultVectorTileStyle,
      zIndex: -5,
      selectable: false
    });

    map.addLayer(layer);

    return () => {
      map.removeLayer(layer);
    };
  }, [map, maxZoom, minZoom, url]);

  return <></>;
}
