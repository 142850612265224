import React from "react";
import { translate } from "react-translate";
import PropTypes from "prop-types";
import { clearUser, registerUser } from "../redux/user";
import { getPageSections } from "../utils/Page";
import { withRouter, Link } from "react-router-dom";
import { getCompanies } from "../redux/companies";
import UserRegistrationForm from "./UserRegistrationForm";
import { connect } from "react-redux";
import Title from "./Title";
import { bindActionCreators } from "redux";
import LoadingIndicator from "./LoadingIndicator";
import scopedBindActionCreators from "../utils/scopedReducer";
import { actions as digiThemeActions } from "../utils/digiTheme";
import config from "../config/TellTur";

const mapDispatchToProps = dispatch => {
  return {
    registerUser: bindActionCreators(registerUser, dispatch),
    clearUser: bindActionCreators(clearUser, dispatch),
    getCompanies: bindActionCreators(getCompanies, dispatch),
    getAllFriluftsraad: scopedBindActionCreators(
      digiThemeActions.readFiltered,
      dispatch,
      "readFriluftsraad"
    ),
    readFriluftsraadMedlemskommuner: scopedBindActionCreators(
      digiThemeActions.readFiltered,
      dispatch,
      "friluftsraadMedlemskommuner"
    )
  };
};

const mapStateToProps = state => ({
  registeredUserEmail: state.user.registeredUserEmail,
  registerUserError: state.user.error,
  user: state.user.user,
  allFriluftsraad: state.readFriluftsraad.records,
  companies: state.companies.companies,
  friluftsraadMedlemskommuner: state.friluftsraadMedlemskommuner.records,
  friluftsraadMedlemskommunerLoading: state.friluftsraadMedlemskommuner.loading
});

class UserRegistrationPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      passwordRepeat: "",
      firstname: "",
      surname: "",
      mobile: "",
      email: "",
      municipality: undefined,
      yearOfBirth: "",
      validationErrors: {},
      selectedFriluftsraadId: "",
      selectedCompanyId: "-1"
    };

    this.redirect = this.redirect.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentWillMount() {
    this.props.clearUser();
    this.props.getAllFriluftsraad(
      config.friluftsraadDt.columns,
      null,
      config.mapProjCode,
      0,
      1000,
      null
    );
  }

  componentDidMount() {
    this.props.readFriluftsraadMedlemskommuner(
      config.friluftsraadMedlemskommunerDt.columns,
      null,
      config.mapProjCode,
      0,
      0,
      null
    );
  }

  redirect() {
    this.props.history.push("/logginn");
  }

  handleSubmit(evt) {
    evt.preventDefault();
    const {
      username,
      password,
      firstname,
      surname,
      mobile,
      email,
      municipality,
      yearOfBirth,
      selectedFriluftsraadId,
      selectedCompanyId,
      consentEmail
    } = this.state;

    if (!this.validateForm()) {
      return;
    }

    const userData = {
      first_name: firstname,
      last_name: surname,
      nick_name: username,
      mobile: mobile,
      address_city: municipality,
      description: yearOfBirth.toString()
    };
    this.props.registerUser(
      firstname,
      surname,
      email,
      password,
      userData,
      yearOfBirth,
      selectedFriluftsraadId,
      selectedCompanyId,
      consentEmail
    );
  }

  validateForm() {
    const { t } = this.props;
    const {
      username,
      password,
      firstname,
      surname,
      mobile,
      email,
      municipality,
      yearOfBirth,
      selectedFriluftsraadId,
      selectedCompanyId
    } = this.state;

    const validationErrors = {};
    if (username.length < 4) {
      validationErrors.username = t("UsernameTooShort");
    }

    if (password !== this.state.passwordRepeat) {
      validationErrors.passwordRepeat = t("PasswordsDoNotMatch");
    }

    if (password.length < 3) {
      validationErrors.password = t("PasswordIsTooShort");
    }

    if (firstname.length < 2) {
      validationErrors.firstname = t("FirstnameNotValid");
    }

    if (surname.length < 2) {
      validationErrors.surname = t("SurnameNotValid");
    }

    var numberRegex = /^-?\d+\.?\d*$/;
    if (mobile.length < 7 || !numberRegex.test(mobile)) {
      validationErrors.mobile = t("MobileNotValid");
    }

    var emailRegEx =
      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    if (!emailRegEx.test(email)) {
      validationErrors.email = t("EmailAddressNotValid");
    }

    if (municipality.length < 2 || isNaN(municipality)) {
      validationErrors.municipality = t("MunicipalityNotValid");
    }

    if (
      yearOfBirth.length !== 4 ||
      !numberRegex.test(yearOfBirth) ||
      yearOfBirth < 1900 ||
      yearOfBirth > new Date().getFullYear()
    ) {
      validationErrors.yearOfBirth = t("YearOfBirthNotValid");
    }

    if (
      selectedFriluftsraadId === undefined ||
      selectedFriluftsraadId === null ||
      selectedFriluftsraadId === ""
    ) {
      validationErrors.selectedFriluftsraadId = t("SelectedFriluftsraadIdIsMissing");
    }

    if (!numberRegex.test(selectedCompanyId)) {
      validationErrors.selectedCompanyId = t("SelectedCompanyIdIsInvalid");
    }

    this.setState({ validationErrors });

    if (Object.keys(validationErrors).length > 0) {
      return false;
    }
    return true;
  }

  handleChange(key, value) {
    this.setState({
      [key]: value
    });
  }

  render() {
    const {
      t,
      page,
      registerUserError,
      allFriluftsraad,
      companies,
      municipalities,
      loading,
      friluftsraadMedlemskommuner
    } = this.props;
    const sections = getPageSections(page);
    const createdUser = this.props.registeredUserEmail;
    return (
      <div className="tell-tur-page">
        {loading ? (
          <div className="trip-list--loader">
            <LoadingIndicator />{" "}
          </div>
        ) : (
          <React.Fragment>
            <Title title={page.title} />
            <h1>{page.title}</h1>
            {!createdUser ? (
              <div>
                {sections && (
                  <div
                    className="tell-tur-page--intro-text"
                    dangerouslySetInnerHTML={{
                      __html: sections["RegisterTextSection"]
                    }}
                  />
                )}
                <UserRegistrationForm
                  {...this.state}
                  companies={companies}
                  registerUserError={registerUserError}
                  handleChange={this.handleChange}
                  handleSubmit={this.handleSubmit}
                  allFriluftsraad={allFriluftsraad}
                  municipalities={municipalities}
                  friluftsraadMedlemskommuner={friluftsraadMedlemskommuner}
                />
              </div>
            ) : (
              <div>
                {sections && (
                  <div
                    className="tell-tur-page--intro-text"
                    dangerouslySetInnerHTML={{
                      __html: sections["SuccessSection"]
                    }}
                  />
                )}
                <Link to="/logginn">{t("ToLogin")}</Link>
              </div>
            )}
          </React.Fragment>
        )}
      </div>
    );
  }
}

UserRegistrationPage.propTypes = {
  t: PropTypes.func.isRequired,
  page: PropTypes.object,
  registeredUserEmail: PropTypes.string,
  registerUserError: PropTypes.object,
  registerUser: PropTypes.func,
  clearUser: PropTypes.func,
  history: PropTypes.object,
  allFriluftsraad: PropTypes.array,
  getAllFriluftsraad: PropTypes.func,
  companies: PropTypes.array,
  getCompanies: PropTypes.func,
  municipalities: PropTypes.array,
  loading: PropTypes.bool,
  readFriluftsraadMedlemskommuner: PropTypes.func.isRequired,
  friluftsraadMedlemskommuner: PropTypes.array
};

export default translate("UserRegistrationPage")(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(UserRegistrationPage))
);
