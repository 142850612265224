import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-translate';

const getServerErrorMessage = (exception, t, meta) => {
  if (exception === undefined || exception === null) {
    return '';
  }
  if (exception.code) {
    if (exception.code === 'TURMAAL_INVALID') {
      return t('InvalidTurmaal');
    }
    if (exception.code === 'USER_EXISTS') {
      return t('UserExists', {username: meta.username});
    }
    if (exception.code === 'USER_INVALID') {
      return t('InvalidUser');
    }
    if (exception.code === 'OWNER_INVALID') {
      return t('InvalidOwner');
    }
    if (exception.code === 'user_3') {
      return t('EmailInUse');
    }
    if (exception.code === 'user_1') {
      return t('EmailInUse');
    }
    if (exception.code === 'TURMAAL_NOT_ACTIVE') {
      return t('TurmaalNotActive');
    }
    if (exception.code === 'TURMAAL_ALREADY_REGISTERED_TODAY') {
      return t(exception.code);
    }
    if (exception.code === 'DATO_ERROR') {
      return t(exception.code);
    }
    if (exception.code === 'INVALID_FAMILY_MEMBER') {
      return t(exception.code);
    }
  }
  if (exception.msg) {
    if (exception.msg === 'cannot_change_frilufstrad_active_competitions') {
      return t(exception.msg);
    }
    return exception.msg;
  }
  if (exception.message) {
    return exception.message;
  }
  return '';
};

const TranslatedServerError = ({ error, t, cssClass, meta = {} }) => {
  const errorMessage = getServerErrorMessage(error, t, meta);
  const errorCssClass = cssClass || 'error';
  return <div className={errorCssClass}>{errorMessage}</div>;
};

TranslatedServerError.propTypes = {
  t: PropTypes.func.isRequired,
  error: PropTypes.object,
  cssClass: PropTypes.string
};

export default translate('TranslatedServerError')(TranslatedServerError);
