import { useMemo } from "react";
import { useLocation } from "react-router";

function useActiveSources() {
  const { pathname, state } = useLocation();

  return useMemo(() => {
    if (/\/alleturmaal\/.*/.test(pathname)) return pathname.split("/")[2].split("+");
    if (state?.activeSources) return state.activeSources.split("+");
    return [];
  }, [pathname, state]);
}

export default useActiveSources;
