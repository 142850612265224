import config from "../config/TellTur";

const validateProfile = (user, familymember) => {
  var numberRegex = /^-?\d+\.?\d*$/;
  var validationErrors = {};

  if (!familymember) {
    validationErrors.general = "General";
    return { valid: false, errors: { validationErrors } };
  }

  if (
    user.modify_date.setHours(0, 0, 0, 0) <
    new Date("03.01.2020").setHours(0, 0, 0, 0)
  ) {
    validationErrors.general = "General";
    return { valid: false, errors: { validationErrors } };
  }

  if (
    !familymember.selected_friluftsraad_uuid ||
    familymember.selected_friluftsraad_uuid === config.emptyFriluftsraadUuid
  ) {
    validationErrors.selected_friluftsraad_uuid =
      "SelectedFriluftsraadIsInvalid";
  }

  if (
    familymember.yearofbirth < new Date().getFullYear() - 120 ||
    familymember.yearofbirth > new Date().getFullYear()
  ) {
    validationErrors.yearofbirth = "YearOfBirthNotValid";
  }

  if (!familymember.nick_name || familymember.nick_name.length < 4) {
    validationErrors.nick_name = "UsernameTooShort";
  }

  if (!user.user_data.first_name || user.user_data.first_name.length < 2) {
    validationErrors.firstname = "FirstnameNotValid";
  }

  if (!user.user_data.last_name || user.user_data.last_name.length < 2) {
    validationErrors.surname = "SurnameNotValid";
  }

  if (
    !user.user_data.mobile ||
    user.user_data.mobile.length < 7 ||
    !numberRegex.test(user.user_data.mobile)
  ) {
    validationErrors.mobile = "MobileNotValid";
  }

  var emailRegEx = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
  if (user.email && !emailRegEx.test(user.email)) {
    validationErrors.email = "EmailAddressNotValid";
  }

  if (
    !user.user_data.address_city ||
    user.user_data.address_city.length < 2 ||
    isNaN(user.user_data.address_city)
  ) {
    validationErrors.municipality = "MunicipalityNotValid";
  }

  if (Object.keys(validationErrors).length > 0) {
    return { valid: false, errors: validationErrors };
  }
  return { valid: true, errors: {} };
};

export { validateProfile };
