import { post } from "../requests";
import { fixKeyValueArraysToJsObject } from "../utils/array";
import { getSessionToken, authReducerActions } from "@avinet/react-adaptive-auth";
import config from "../config/TellTur";

// ------------------------------------
// Constants
// ------------------------------------
export const STATISTICS_TYPE = Object.freeze({
  POINTS: "POINTS",
  UNIQUE: "UNIQUE"
});

export const READING_POINT_STATS = "READING_POINT_STATS";
export const POINT_STATS_READ = "POINT_STATS_READ";

export const READING_UNIQUE_STATS = "READING_UNIQUE_STATS";
export const UNIQUE_STATS_READ = "UNIQUE_STATS_READ";

export const READING_STATS = "READING_STATS";
export const STATS_READ = "STATS_READ";

export const READING_COMPANY_STATS = "READING_COMPANY_STATS";
export const COMPANY_STATS_READ = "COMPANY_STATS_READ";

// ------------------------------------
// Actions
// ------------------------------------
const readingStats = statisticsType => ({
  type: READING_STATS,
  payload: {
    statisticsType
  }
});

const statsRead = (errorMessage, statisticsType, stats) => ({
  type: STATS_READ,
  payload: {
    error: errorMessage,
    statisticsType,
    stats
  }
});

const readingCompanyStats = statisticsType => ({
  type: READING_COMPANY_STATS,
  payload: {
    statisticsType
  }
});

const companyStatsRead = (errorMessage, statisticsType, stats) => ({
  type: COMPANY_STATS_READ,
  payload: {
    error: errorMessage,
    statisticsType,
    stats
  }
});

const getUrl = statisticsType => {
  switch (statisticsType) {
    case STATISTICS_TYPE.POINTS:
      return config.adaptiveUrl + "WebServices/telltur/Trips.asmx/ReadStatisticsWithSearch";
    case STATISTICS_TYPE.UNIQUE:
      return (
        config.adaptiveUrl +
        "WebServices/telltur/Trips.asmx/ReadUniqueDestinationsStatisticsWithSearch"
      );
    default:
      return "";
  }
};

export function readCompanyStatistics(companyId, selectedFriluftsraadId, statisticsType, year) {
  return dispatch => {
    dispatch(readingCompanyStats(statisticsType));

    const start = 0;
    const limit = 0;

    const fromDate =
      selectedFriluftsraadId === config.vesteralenFriluftsraadUuid && year >= 2023
        ? new Date(Date.UTC(year, 3, 1))
        : new Date(Date.UTC(year, 0, 1));

    const toDate =
      selectedFriluftsraadId === config.vesteralenFriluftsraadUuid && year >= 2023
        ? new Date(Date.UTC(year, 11, 1))
        : new Date(Date.UTC(year + 1, 0, 1));

    const filter = {
      filterColumns: [
        {
          name: "date",
          value: fromDate,
          comparisonOperator: ">=",
          logicalOperator: "AND"
        },
        {
          name: "date",
          value: toDate,
          comparisonOperator: "<",
          logicalOperator: "AND"
        },
        {
          name: "bedrift_id",
          value: companyId,
          comparisonOperator: "=",
          logicalOperator: "AND"
        }
      ],
      sortColumns: [
        {
          name: "aggregate",
          sortDesc: true
        }
      ]
    };
    const url = `
      ${config.adaptiveUrl}${
      statisticsType === STATISTICS_TYPE.POINTS
        ? "WebServices/telltur/Trips.asmx/ReadMyCompanyPointStatistics"
        : "WebServices/telltur/Trips.asmx/ReadMyCompanyUniqueDestinationsStatistics"
    }`;

    const data = {
      start,
      limit,
      filter
    };

    if (statisticsType === STATISTICS_TYPE.POINTS) {
      data.search = null;
    }

    post(
      url,
      {
        Accept: "application/json",
        gm_lang_code: config.gm_lang_code,
        gm_session_id: getSessionToken()
      },
      data,
      (err, response) => {
        if (err || !response.ok) {
          dispatch(
            companyStatsRead({ message: "Error returned from service" }, statisticsType, undefined)
          );
          return;
        }
        response = response.body.d;

        if (response.sessionExpired) {
          dispatch(authReducerActions.sessionExpired());
        }

        if (response.exception || !response.success) {
          dispatch(
            companyStatsRead(
              response.exception || { message: "Error returned from service" },
              statisticsType,
              undefined
            )
          );
          return;
        }
        var records = fixKeyValueArraysToJsObject(response.records);
        dispatch(companyStatsRead(null, statisticsType, records));
      }
    );
  };
}

export function ReadAllCompaniesPointStatistics(selectedFriluftsraadId, statisticsType, year) {
  return dispatch => {
    dispatch(readingCompanyStats(statisticsType));

    const start = 0;
    const limit = 0;

    const fromDate =
      selectedFriluftsraadId === config.vesteralenFriluftsraadUuid && year >= 2023
        ? new Date(Date.UTC(year, 3, 1))
        : new Date(Date.UTC(year, 0, 1));

    const toDate =
      selectedFriluftsraadId === config.vesteralenFriluftsraadUuid && year >= 2023
        ? new Date(Date.UTC(year, 11, 1))
        : new Date(Date.UTC(year + 1, 0, 1));

    const filter = {
      filterColumns: [
        {
          name: "selected_friluftsraad_uuid",
          value: selectedFriluftsraadId,
          comparisonOperator: "=",
          logicalOperator: "AND"
        },
        {
          name: "date",
          value: fromDate,
          comparisonOperator: ">=",
          logicalOperator: "AND"
        },
        {
          name: "date",
          value: toDate,
          comparisonOperator: "<",
          logicalOperator: "AND"
        }
      ],
      sortColumns: [
        {
          name: "snitt_poeng",
          sortDesc: true
        }
      ]
    };

    const url =
      config.adaptiveUrl + "WebServices/telltur/Trips.asmx/ReadAllCompaniesPointStatistics";
    post(
      url,
      {
        Accept: "application/json",
        gm_lang_code: config.gm_lang_code,
        gm_session_id: getSessionToken()
      },
      {
        start,
        limit,
        filter
      },
      (err, response) => {
        if (err || !response.ok) {
          dispatch(
            companyStatsRead({ message: "Error returned from service" }, statisticsType, undefined)
          );
          return;
        }
        response = response.body.d;

        if (response.sessionExpired) {
          dispatch(authReducerActions.sessionExpired());
        }

        if (response.exception || !response.success) {
          dispatch(
            companyStatsRead(
              response.exception || { message: "Error returned from service" },
              statisticsType,
              undefined
            )
          );
          return;
        }
        var records = fixKeyValueArraysToJsObject(response.records);
        dispatch(companyStatsRead(null, statisticsType, records));
      }
    );
  };
}

export function readStatistics(start = 0, limit = 0, selectedFriluftsraadId, statisticsType, year) {
  return dispatch => {
    dispatch(readingStats(statisticsType));

    const filter = {
      filterColumns: [
        {
          name: "date",
          value: new Date(Date.UTC(year, 0, 1)),
          comparisonOperator: ">=",
          logicalOperator: "AND"
        },
        {
          name: "date",
          value: new Date(Date.UTC(year + 1, 0, 1)),
          comparisonOperator: "<",
          logicalOperator: "AND"
        }
      ],
      sortColumns: [
        {
          name: "aggregate",
          sortDesc: true
        }
      ]
    };

    if (selectedFriluftsraadId) {
      filter.filterColumns.push({
        name: "selected_friluftsraad_uuid",
        value: selectedFriluftsraadId,
        comparisonOperator: "=",
        logicalOperator: "AND"
      });
    }

    const url = getUrl(statisticsType);
    post(
      url,
      {
        Accept: "application/json",
        gm_lang_code: config.gm_lang_code,
        gm_session_id: getSessionToken()
      },
      {
        start,
        limit,
        filter
      },
      (err, response) => {
        if (err || !response.ok) {
          dispatch(
            statsRead({ message: "Error returned from service" }, statisticsType, undefined)
          );
          return;
        }
        response = response.body.d;

        if (response.sessionExpired) {
          dispatch(authReducerActions.sessionExpired());
        }

        if (response.exception || !response.success) {
          dispatch(
            statsRead(
              response.exception || { message: "Error returned from service" },
              statisticsType,
              undefined
            )
          );
          return;
        }
        var records = fixKeyValueArraysToJsObject(response.records);
        dispatch(statsRead(null, statisticsType, records));
      }
    );
  };
}

export function ReadStatisticsWithSearch(
  selectedFriluftsraadId,
  statisticsType,
  year,
  search = null
) {
  return dispatch => {
    dispatch(readingStats(statisticsType));

    const start = 0;
    const limit = 0;

    const filter = {
      filterColumns: [
        {
          name: "date",
          value: new Date(Date.UTC(year, 0, 1)),
          comparisonOperator: ">=",
          logicalOperator: "AND"
        },
        {
          name: "date",
          value: new Date(Date.UTC(year + 1, 0, 1)),
          comparisonOperator: "<",
          logicalOperator: "AND"
        }
      ],
      sortColumns: [
        {
          name: "row"
        }
      ]
    };

    if (selectedFriluftsraadId) {
      filter.filterColumns.push({
        name: "selected_friluftsraad_uuid",
        value: selectedFriluftsraadId,
        comparisonOperator: "=",
        logicalOperator: "AND"
      });
    }

    const url = getUrl(statisticsType);
    post(
      url,
      {
        Accept: "application/json",
        gm_lang_code: config.gm_lang_code
      },
      {
        start,
        limit,
        filter,
        search
      },
      (err, response) => {
        if (err || !response.ok) {
          dispatch(
            statsRead({ message: "Error returned from service" }, statisticsType, undefined)
          );
          return;
        }
        response = response.body.d;

        if (response.sessionExpired) {
          dispatch(authReducerActions.sessionExpired());
        }

        if (response.exception || !response.success) {
          dispatch(
            statsRead(
              response.exception || { message: "Error returned from service" },
              statisticsType,
              undefined
            )
          );
          return;
        }
        var records = fixKeyValueArraysToJsObject(response.records);
        dispatch(statsRead(null, statisticsType, records));
      }
    );
  };
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  company: {
    loading: false,
    error: undefined,
    statisticsType: undefined,
    results: undefined
  },
  friluftsraad: {
    loading: false,
    error: undefined,
    statisticsType: undefined,
    results: undefined
  }
};

export function statisticsReducer(state = initialState, action) {
  switch (action.type) {
    case READING_STATS:
      return Object.assign({}, state, {
        friluftsraad: {
          loading: true,
          error: null,
          statisticsType: action.payload.statisticsType,
          results: null
        }
      });
    case STATS_READ:
      return Object.assign({}, state, {
        friluftsraad: {
          loading: false,
          error: action.payload.error ? new Error(action.payload.error) : null,
          statisticsType: action.payload.statisticsType,
          results: action.payload.stats
        }
      });
    case READING_COMPANY_STATS:
      return Object.assign({}, state, {
        company: {
          loading: true,
          error: null,
          statisticsType: action.payload.statisticsType,
          results: null
        }
      });
    case COMPANY_STATS_READ:
      return Object.assign({}, state, {
        company: {
          loading: false,
          error: action.payload.error ? new Error(action.payload.error) : null,
          statisticsType: action.payload.statisticsType,
          results: action.payload.stats
        }
      });
    default:
      return state;
  }
}
