import React from "react";
import { translate } from "react-translate";
import { LoginForm, authReducerActions } from "@avinet/react-adaptive-auth";
import { connect } from "react-redux";
import { getUserDetails } from "../redux/user";
import { withRouter, Link } from "react-router-dom";
import PropTypes from "prop-types";
import Title from "./Title";
import LoadingIndicator from "./LoadingIndicator";
import "./LoginPage.scss";

const mapDispatchToProps = dispatch => {
  return {
    getUserDetails: () => {
      dispatch(getUserDetails());
    },
    loggedIn: () => {
      dispatch(authReducerActions.loggedIn());
    }
  };
};

const mapStateToProps = state => ({
  sessionExpired: state.auth.sessionExpired
});

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.onLogin = this.onLogin.bind(this);
    this.onLoggingIn = this.onLoggingIn.bind(this);

    this.state = {
      loading: false
    };
  }

  onLogin() {
    this.props.getUserDetails();
    this.props.loggedIn();
    this.props.history.push("/minprofil");
  }

  onLoggingIn(loading) {
    this.setState({ loading });
  }

  render() {
    const { t, page, sessionExpired } = this.props;
    return (
      <div className="tell-tur-page">
        <Title title={page.title} />
        <h1>{page.title}</h1>
        <div
          className="tell-tur-page--intro-text"
          dangerouslySetInnerHTML={{ __html: page.content }}
        />
        {sessionExpired && (
          <p className="login-page--session-expired-container">{t("sessionExpired")}</p>
        )}
        <LoginForm
          baseUrl={this.props.baseUrl}
          showLabels
          loginText={t("LogIn")}
          emailLabelText={t("Username")}
          passwordLabelText={t("Password")}
          errorText={t("Error")}
          onLogin={this.onLogin}
          onLoading={this.onLoggingIn}
        >
          {this.state.loading && (
            <div className="loader">
              <LoadingIndicator />
            </div>
          )}
        </LoginForm>
        <div className="tell-tur-page--footer">
          <span>
            <Link to="/glemtpassord">{t("ForgotPassword")}</Link>
          </span>{" "}
          | <span>{t("NewUser")} </span>
          <span>
            <Link to="/brukerregistrering">{t("RegisterHere")}</Link>
          </span>
        </div>
      </div>
    );
  }
}

LoginPage.propTypes = {
  history: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  page: PropTypes.object,
  baseUrl: PropTypes.string,
  getUserDetails: PropTypes.func,
  loggedIn: PropTypes.func.isRequired,
  sessionExpired: PropTypes.bool
};

export default withRouter(
  translate("LoginPage")(connect(mapStateToProps, mapDispatchToProps)(LoginPage))
);
