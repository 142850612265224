import React from "react";
import PropTypes from "prop-types";
import LoadingIndicator from "../LoadingIndicator";
import "./ResultList.scss";

const mapResults = (
  results,
  displayProps,
  member,
  currentPage,
  pageLimit,
  ignorePlace
) => {
  return (
    <div className="result-list--row-list">
      {results.map((r, i) => {
        const prop1 = r[displayProps[0]];
        const prop2 = r[displayProps[1]];
        const prop3 = r[displayProps[2]];
        const prop4 = r[displayProps[3]];

        let label =
          !r.place || ignorePlace
            ? ((currentPage || 1) - 1) * pageLimit + i + 1
            : r.place;
        return (
          <div
            key={i}
            className={[
              "result-list--row",
              member && member.nick_name === prop1 ? "is-member" : ""
            ].join(" ")}
          >
            <div className="result-list--column0">{`${
              isNaN(label) ? i + 1 : label
            }.`}</div>
            <div className="result-list--column1">{prop1}</div>
            <div className="result-list--column2">{prop2}</div>
            {prop3 && <div className="result-list--column3">{prop3}</div>}
            {prop4 && <div className="result-list--column4">{prop4}</div>}
          </div>
        );
      })}
    </div>
  );
};

const ResultList = props => {
  const {
    results,
    headers,
    displayProps,
    title,
    loading,
    member,
    currentPage,
    pageLimit,
    children,
    ignorePlace
  } = props;

  return (
    <div className="result-list">
      {title && <div className="results-list--results-title">{title}</div>}
      <div className="result-list--row header">
        {headers.map((h, i) => (
          <div
            key={i}
            className={`result-list--column${i} result-list--header`}
          >
            {h}
          </div>
        ))}
      </div>
      {loading && (
        <div className="result-list--loading-container">
          <LoadingIndicator />
        </div>
      )}
      {results &&
        mapResults(
          results,
          displayProps,
          member,
          currentPage,
          pageLimit,
          ignorePlace
        )}
      {children}
    </div>
  );
};

ResultList.propTypes = {
  title: PropTypes.string,
  results: PropTypes.array,
  headers: PropTypes.array.isRequired,
  displayProps: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  member: PropTypes.object,
  currentPage: PropTypes.number,
  pageLimit: PropTypes.number,
  children: PropTypes.element
};
export default ResultList;
