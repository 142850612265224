import { Component } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { clearUser } from "../redux/user";
import { clearMembers } from "../redux/familymembers";
import { logout, authReducerActions } from "@avinet/react-adaptive-auth";

const mapDispatchToProps = dispatch => {
  return {
    clearUser: bindActionCreators(clearUser, dispatch),
    clearMembers: bindActionCreators(clearMembers, dispatch),
    loggedOut: () => {
      dispatch(authReducerActions.loggedOut());
    }
  };
};

class LogoutView extends Component {
  componentDidMount() {
    const { clearMembers, clearUser, loggedOut, history } = this.props;

    logout();
    clearUser();
    clearMembers();
    loggedOut();
    history.push("/");
  }

  render() {
    return null;
  }
}

LogoutView.propTypes = {
  history: PropTypes.object.isRequired,
  loggedOut: PropTypes.func.isRequired,
  clearMembers: PropTypes.func.isRequired,
  clearUser: PropTypes.func.isRequired
};

export default withRouter(connect(null, mapDispatchToProps)(LogoutView));
