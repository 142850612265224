import { readFiltered } from "./DigiTheme";
import config from "../config/TellTur";

const columns = ["id", "navn", "beskrivelse"];
// ------------------------------------
// Constants
// ------------------------------------
export const READING_COMPANIES = "READING_COMPANIES";
export const COMPANIES_READ = "COMPANIES_READ";

// ------------------------------------
// Actions
// ------------------------------------

const readCompanies = () => ({ type: READING_COMPANIES });

const companiesRead = (error, companies) => ({
  type: COMPANIES_READ,
  payload: {
    error,
    companies
  }
});

// ------------------------------------
// Action creators
// ------------------------------------
export function getCompanies(friluftsraadId) {
  return dispatch => {
    dispatch(readCompanies());

    const filter = {
      filterColumns: [
        {
          name: "_acl",
          value: [friluftsraadId],
          comparisonOperator: "@>",
          netType: "Guid[]"
        }
      ],
      sortColumns: [
        {
          name: "navn"
        }
      ],
      secondFilter: [],
      defaultFilter: [],
      distinctColumns: []
    };

    readFiltered(
      config.companyDigiThemeUuid,
      columns,
      filter,
      (err, response) => {
        if (err || !response.ok) {
          dispatch(
            companiesRead(
              err || { message: "Received an error from server" },
              undefined
            )
          );
          return;
        }
        response = response.body.d;

        if (response.exception || !response.success) {
          dispatch(
            companiesRead(
              response.exception || {
                message: "Received an error from server"
              },
              undefined
            )
          );
          return;
        }

        dispatch(companiesRead(undefined, response.records));
      }
    );
  };
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  loading: false,
  error: undefined,
  companies: undefined
};

export function companyReducer(state = initialState, action) {
  switch (action.type) {
    case READING_COMPANIES:
      return Object.assign({}, state, {
        loading: true,
        companies: undefined,
        error: undefined
      });
    case COMPANIES_READ:
      return Object.assign({}, state, {
        loading: false,
        error: action.payload.error,
        companies: action.payload.companies
      });
    default:
      return state;
  }
}
