import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../../components/Icon';
import Modal from '../../components/Modal';
import LoadingIndicator from '../../components/LoadingIndicator';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { translate } from 'react-translate';
import { withRouter, Link } from 'react-router-dom';
import { unjoinCompetition, readJoinedCompetitions } from '../../redux/competition';

import './CompetitionPage.scss';

const mapDispatchToProps = dispatch => {
  return {
    readJoinedCompetitions: bindActionCreators(readJoinedCompetitions, dispatch),
    unjoinCompetition: bindActionCreators(unjoinCompetition, dispatch)
  };
};

const mapStateToProps = state => ({
  unjoiningCompetition: state.joinCompetitionReducer.unjoining,
  unjoinCompetitionSuccess: state.joinCompetitionReducer.success,
  joinedCompetitionsLoading: state.joinCompetitionReducer.loading,
  joinedCompetitions: state.joinCompetitionReducer.competitions
});

class CompetitionPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      loading: false
    };
    this.handleUnjoinCompetition = this.handleUnjoinCompetition.bind(this);
  }

  componentDidMount() {
    this.props.readJoinedCompetitions();
  }

  handleUnjoinCompetition(id) {
    this.props.unjoinCompetition(id);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.unjoiningCompetition && !this.props.unjoiningCompetition) {
      this.props.readJoinedCompetitions();
    }
  }

  get memberCompetitions() {
    const { joinedCompetitions } = this.props;
    const memberCompetitions = {};

    joinedCompetitions &&
      joinedCompetitions.forEach(c => {
        if (!memberCompetitions[c.user_id]) {
          memberCompetitions[c.user_id] = [];
        }

        memberCompetitions[c.user_id].push(c);
      });

    return memberCompetitions;
  }

  render() {
    const { showModal } = this.state;
    const { t } = this.props;
    const memberCompetitions = this.memberCompetitions;
    return (
      <div className="tell-tur-page">
        <div className="tell-tur-page--intro-text">
          <h1>{t('title')}</h1>
          <p>
            {t('Description')}
            <Link to="/friluftsrad">{t('LinkToFriluftsrad')}</Link>.
          </p>
          <p>{t('Note')}</p>
        </div>
        {Object.keys(memberCompetitions).length === 0 ? (
          <div className="competition-page--no-joined-competitons">
            <p>{t('NoMemberCompetitions')}</p>
          </div>
        ) : (
          Object.values(memberCompetitions).map(mca => {
            return (
              <div key={mca[0].nick_name} className="competition-page__wrapper--members">
                <div className="wrapper__members--member">{mca[0].nick_name}</div>
                <ul className="members__member--competitions-list">
                  {mca.map(mc => (
                    <li
                      key={mc.nick_name + '_' + mc.competition_id}
                      className="member__competition"
                    >
                      {mc.title}
                      <button
                        onClick={() => this.setState({ showModal: true, competitionId: mc.id })}
                      >
                        <Icon name="cross" />
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            );
          })
        )}
        {this.props.unjoinCompetitionLoadig && (
          <div className="loader">
            <LoadingIndicator />
          </div>
        )}
        {showModal && (
          <Modal>
            <div>
              <h3>{t('CancelationConfirm')}</h3>
              <p>{t('CancelationDescription')}</p>
              <p>{t('CancelationDescriptionNote')}</p>
              <div className="btn-flex-container">
                <button
                  onClick={() => this.setState({ showModal: false })}
                  className="btn btn-secondary"
                >
                  {t('ButtonCancel')}
                </button>

                <button
                  className="btn btn-primary"
                  onClick={() => {
                    this.handleUnjoinCompetition(this.state.competitionId);
                    this.setState({ showModal: false });
                  }}
                >
                  {t('ButtonConfirm')}
                </button>
              </div>
            </div>
          </Modal>
        )}
      </div>
    );
  }
}

CompetitionPage.propTypes = {
  joinedCompetitions: PropTypes.array,
  readJoinedCompetitions: PropTypes.func.isRequired,
  unjoinCompetition: PropTypes.func.isRequired,
  unjoinCompetitionLoadig: PropTypes.bool,
  unjoiningCompetition: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(translate('CompetitionPage')(CompetitionPage)));
