import React, { useState } from "react";
import { WmsLayer } from "@avinet/react-openlayers";
import config from "../../config/TellTur";
import ErrorBoundary from "../ErrorBoundary";

const StiLayer = ({ articleId }) => {
  // Remount on error due to some unmount bug in WmsLayer
  const [version, setVersion] = useState(0);
  return (
    <ErrorBoundary key={version} onError={() => setVersion(v => v + 1)}>
      {articleId && (
        <WmsLayer
          key="wms-sti-layer"
          id="wms-layer-sti"
          uri="https://friluftsforvaltningogc.avinet.no/wms.ashx?"
          layerName={config.sti.grayStiLayer}
          singleTile
          zIndex={-2}
          minZoom={7}
        />
      )}
      <WmsLayer
        key="wms-active-sti-layer"
        name="active-sti"
        id={"wms-layer-sti-active-" + articleId}
        uri="https://friluftsforvaltningogc.avinet.no/mapserv.ashx?"
        layerName={config.sti.activeStiLayer}
        version="1.3.0"
        zIndex={-1}
        minZoom={7}
        wmsLayerParams={{
          DIM_FK_TURMAL: articleId,
          GUI: config.sti.guiId
        }}
      />
    </ErrorBoundary>
  );
};

export default StiLayer;
