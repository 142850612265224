import React from "react";
import PropTypes from "prop-types";
import Icon from "./Icon";
import { translate } from "react-translate";
import { Link } from "react-router-dom";

import headerImg from "../static/header-bg.jfif";
import benk from "../static/benk.png";
import fiske from "../static/fiske.svg";
import sykle from "../static/sykle.svg";

import "./IndexPage.scss";
import { fixDate } from "../utils/date";

const IndexPage = ({ competitions, friluftsraadHref, t, getAssocietedFriluftsraadUri }) => {
  var tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  let activeCompetitions = [];

  if (competitions) {
    activeCompetitions = competitions.filter(competition => {
      const endDate = fixDate(competition.end_date);
      const hasExpired = competition.end_date ? endDate < tomorrow : false;
      return !hasExpired;
    });
  }

  return (
    <div className="telltur--index">
      <div className="telltur-index--banners">
        {/*<HideableBanner
          url="https://www.friluftsrad.no/om-fl/forvaltningslosning/korona"
          textContent="Pga. smitterisiko skal turpostkasser ikke brukes. Registrer din
              tur gjennom appen Norgeskart friluftsliv"
        />*/}
        {activeCompetitions && activeCompetitions.length > 0 && (
          <div className="telltur-index--active-competitions">
            <p>
              {t("ActiveCompetitions").replace(
                "{0}",
                activeCompetitions && activeCompetitions.length
              )}{" "}
              - <Link to={friluftsraadHref}>{t("JoinNow")}</Link>
            </p>
          </div>
        )}
      </div>
      <div className="telltur-index--img-container">
        <img
          className="telltur--index-image"
          src={headerImg}
          alt="Sildviktind panorama, foto: Harald Groven (CC BY-SA 2.0)"
          title="Sildviktind panorama, foto: Harald Groven (CC BY-SA 2.0)"
        />
        <div className="button-container">
          <Link to="/alleturmaal" className="btn btn-primary btn-uppercase">
            <Icon name="map" />
            {t("FindTrip")}
          </Link>
          <Link
            to="/friluftsrad"
            className={[
              "btn btn-primary btn-uppercase",
              competitions && competitions.length > 0 && getAssocietedFriluftsraadUri
                ? "active-competitions--btn"
                : ""
            ].join(" ")}
          >
            <Icon name="medal" />
            {t("CheckCompetitions")}
          </Link>
        </div>
      </div>

      <div className="telltur-index--description-container">
        <p className="telltur-index--description">
          <span className="strong">TellTur</span> {t("Description1")}{" "}
          <Link to="/alleturmaal" className="link">
            {t("UseMapLink")}
          </Link>
          {t("Description2")}
          <Link to="/friluftsrad" className="link">
            {t("GoToFrLink")}
          </Link>
        </p>
        <p className="telltur-index--description">{t("Description3")}</p>
        <center>
          <Link to="/omtelltur" className="link">
            {t("GoToAboutLink")}
          </Link>
        </center>
        <h3>{t("GoodLuck")}</h3>
        <img className="illustration fiske" src={fiske} alt="fiske illustration" />
        <img className="illustration sykle" src={sykle} alt="sykle illustration" />
        <img className="illustration benk" src={benk} alt="benk illustration" />
      </div>
    </div>
  );
};

IndexPage.propTypes = {
  t: PropTypes.func.isRequired,
  competitions: PropTypes.array,
  friluftsraadHref: PropTypes.string.isRequired
};

export default translate("IndexPage")(IndexPage);
